import { configureStore } from '@reduxjs/toolkit';
import { FirebaseApp } from 'firebase/app';
import { Auth } from 'firebase/auth';
import { Firestore } from 'firebase/firestore';
import { FirebaseStorage } from 'firebase/storage';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import combinedReducers from './reducers/rootReducer';

// Add to redux thunks to avoid cyclic dependencies by direct access usage
type FirebaseMiddleware = {
  getFirebase: () => FirebaseApp;
  getAuth: () => Auth;
  getFirestore: () => Firestore;
  getStorage: () => FirebaseStorage;
  getDefautBucket: () => FirebaseStorage;
  getProfilePicsBucket: () => FirebaseStorage;
};

export default function initStore(storeInput: {
  firebaseApp: FirebaseApp;
  auth: Auth;
  firestore: Firestore;
  storage: FirebaseStorage;
  defaultBucket: FirebaseStorage;
  profilePicsBucket: FirebaseStorage;
}) {
  const extraArgument: FirebaseMiddleware = {
    getFirebase: () => storeInput.firebaseApp,
    getFirestore: () => storeInput.firestore,
    getStorage: () => storeInput.storage,
    getAuth: () => storeInput.auth,
    getDefautBucket: () => storeInput.defaultBucket,
    getProfilePicsBucket: () => storeInput.profilePicsBucket
  };

  return configureStore({
    reducer: combinedReducers,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: { extraArgument },
        serializableCheck: false
      }),
    devTools: !process.env.REACT_APP_ENV?.includes('production')
  });
}

export type AppStore = ReturnType<typeof initStore>;
export type ThunkMiddleware = FirebaseMiddleware;
export type AppState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];

export type ThunkActionType<R = void> = ThunkAction<R, AppState, ThunkMiddleware, AnyAction>;
