import authAxios from 'src/util/axios';
import { getSnackbar } from 'src/util/helpers/server';
import { updateUi } from '../reducers/uiReducer';
import { ThunkActionType } from '../store';
import { AdminCallPayload } from '../types/common';
import history from 'src/history';
import { LicenseType } from 'flyid-core/dist/Database/Models';

export type createLicensesParams = {
  company: string;
  expDate: string;
  description: string;
  owner: string;
  licenseType: LicenseType;
  /** Defaults to false */
  spotOnly?: boolean;
  hasMatrixScan: boolean;
  /** Only used when Api key is *not* custom */
  useTestsLicense?: boolean;
  /** Only used when Api key is custom */
  scanditApiKey?: string;
  /** Only for Fly.id */
  droneSerials?: string[];
  /** Only for Count.id */
  maxDevices?: number;
};

export const createLicenses =
  (data: createLicensesParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(updateUi({ loadingButton: { isLicenseActionLoading: true } }));
    const isCountId = data.licenseType === LicenseType.COUNTID;
    const useCustomLicense = !!data.scanditApiKey;

    const posArgs: AdminCallPayload['posArgs'] = [data.company, data.expDate];
    if (isCountId) posArgs.push(String(data.maxDevices));
    else data.droneSerials?.forEach((s) => posArgs.push(s));

    const _data: AdminCallPayload = {
      posArgs,
      listArgs: {
        description: [data.description],
        owner: [data.owner],
        spotOnly: [data.spotOnly ?? false],
        licenseType: [data.licenseType],
        scanditApiKey: useCustomLicense ? [data.scanditApiKey!] : undefined,
        production: useCustomLicense ? undefined : [!data.useTestsLicense],
        // Matrix scan flags are used depending whether using custom or default licenses
        [useCustomLicense ? 'hasMatrixScan' : 'useMatrixScan']: [data.hasMatrixScan],
        isPilot: isCountId ? undefined : [data.licenseType === LicenseType.FLYID_PILOT]
      }
    };

    authAxios(getAuth(), getState(), {
      url: `/admin/execute`,
      method: 'post',
      data: _data,
      params: {
        command: isCountId ? 'createCountidLicense' : 'createFlyidLicenses'
      }
    })
      .then((res) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(res),
            loadingButton: {
              isLicenseActionLoading: false
            }
          })
        );
        history.push('/manageLicenses');
      })
      .catch((err: Error) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(err),
            loadingButton: {
              isLicenseActionLoading: false
            }
          })
        );
      });
  };

export type replaceFlyidLicenseParams = {
  currentLicense: string;
  newLicense: string;
  deleteCurrent?: boolean;
};

export const replaceFlyidLicense =
  (data: replaceFlyidLicenseParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(updateUi({ loadingButton: { isLicenseActionLoading: true } }));

    const _data: AdminCallPayload = {
      posArgs: [data.currentLicense, data.newLicense],
      listArgs: {
        deleteCurrent: [data.deleteCurrent ?? false]
      }
    };

    authAxios(getAuth(), getState(), {
      url: `/admin/execute`,
      method: 'post',
      data: _data,
      params: {
        command: 'replaceDroneLicense'
      }
    })
      .then((res) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(res),
            loadingButton: {
              isLicenseActionLoading: false
            }
          })
        );
        history.push('/manageLicenses');
      })
      .catch((err: Error) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(err),
            loadingButton: {
              isLicenseActionLoading: false
            }
          })
        );
      });
  };

export type EditFlyidLicenseParams = {
  license: string;
  company?: string;
  ownerId?: string;
  description?: string;
  expDate?: string;
  authDomains?: string[];
  scanditLicenseKey?: string;
  hasMatrixScan?: boolean;
  spotOnly?: boolean;
};

export const editFlyidLicense =
  (data: EditFlyidLicenseParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(updateUi({ loadingButton: { isLicenseActionLoading: true } }));

    const _data: AdminCallPayload = {
      posArgs: [data.license],
      listArgs: {
        company: data.company ? [data.company] : undefined,
        ownerId: data.ownerId ? [data.ownerId] : undefined,
        description: data.description ? [data.description] : undefined,
        expDate: data.expDate ? [data.expDate] : undefined,
        authDomains: data.authDomains,
        scanditLicenseKey: data.scanditLicenseKey ? [data.scanditLicenseKey] : undefined,
        hasMatrixScan: [Boolean(data.scanditLicenseKey) && (data.hasMatrixScan ?? false)],
        spotOnly: [data.spotOnly ?? false]
      }
    };

    authAxios(getAuth(), getState(), {
      url: `/admin/execute`,
      method: 'post',
      data: _data,
      params: {
        command: 'editLicense'
      }
    })
      .then((res) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(res),
            loadingButton: {
              isLicenseActionLoading: false
            }
          })
        );
        history.push('/manageLicenses');
      })
      .catch((err: Error) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(err),
            loadingButton: {
              isLicenseActionLoading: false
            }
          })
        );
      });
  };

export type removeFlyidLicensesParams = {
  licenses: string[];
  skipCheck?: boolean;
};
export const removeFlyidLicenses =
  (data: removeFlyidLicensesParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(
      updateUi({
        backdrop: {
          message: { msgCode: 'admin.manLicRemovLic', msg: 'Removing license(s)...' },
          show: true
        }
      })
    );

    const _data: AdminCallPayload = {
      posArgs: [...data.licenses],
      listArgs: {
        skipCheck: [data.skipCheck ?? true]
      }
    };

    authAxios(getAuth(), getState(), {
      url: `/admin/execute`,
      method: 'post',
      data: _data,
      params: {
        command: 'removeLicenses'
      }
    })
      .then((res) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(res),
            loadingButton: {
              isLicenseActionLoading: false
            }
          })
        );
      })
      .catch((err: Error) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(err),
            loadingButton: {
              isLicenseActionLoading: false
            }
          })
        );
      });
  };
