import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { query, where } from 'firebase/firestore';
import { getCompaniesCol, getUsersCol } from 'flyid-core/dist/Util/database';
import React, { useState } from 'react';
import { useCollectionOnce } from 'react-firebase-hooks/firestore';
import { useIntl } from 'react-intl';
import { buildCollectionRef, querySnapToMap } from 'src/firebase/firestore';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import useStateReducer from 'src/hooks/useStateReducer';
import { Actions } from 'src/redux/actions/actionTypes';
import { createLicensesParams } from 'src/redux/actions/licenseActions';
import { MyDialogState, updateUi } from 'src/redux/reducers/uiReducer';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import LoadingButton from '../widgets/LoadingButton';
// eslint-disable-next-line import/no-unassigned-import
import 'dayjs/locale/pt-br';
import { LicenseType } from 'flyid-core/dist/Database/Models';
import { useStoredState } from 'src/util/web';

const useStyles = appMakeStyles(({ resizableContainer, spacing }) => ({
  container: {
    ...resizableContainer(2),
    marginLeft: 0,
    maxWidth: '900px'
  },
  mainGrid: {
    minWidth: '650px'
  },
  titleContainer: {
    marginBottom: spacing(4),
    maxWidth: '850px'
  },
  tooltip: {
    alignSelf: 'center',
    marginLeft: spacing(2)
  },
  button: {
    display: 'block',
    marginTop: spacing(1)
  }
}));

const HumanReadableLicenseTypes = {
  [LicenseType.FLYID]: 'Fly.id',
  [LicenseType.FLYID_PILOT]: 'Fly.id Pilot',
  [LicenseType.COUNTID]: 'Count.id'
};

const initialLicenseData = {
  owner: '',
  expDate: dayjs(new Date()),
  droneSerials: [] as string[], // flyid/pilot only
  licenseType: LicenseType.FLYID,
  maxDevices: 3, // countid only
  description: '',
  spotOnly: false,
  scanditApiKey: '',
  hasMatrixScan: false,
  useTestsLicense: false
};
type State = typeof initialLicenseData;

const AddLicense: React.FC = () => {
  const classes = useStyles();
  const { spacing, text, select } = useAppTheme();
  const { $t } = useIntl();
  const dispatch = useAppDispatch();

  const { ui } = useAppSelector((s) => ({
    ui: s.ui
  }));

  const [state, setState] = useStateReducer<State>(Object.assign({}, initialLicenseData));
  const [customScanditApiKey, setCustomScanditApiKey] = useState(false);
  const [companySelected, setCompanySelected] = useStoredState<string>('companySelect', '');

  const [companiesQS, loadingCompanies, errorCompanies] = useCollectionOnce(
    buildCollectionRef(getCompaniesCol())
  );
  const companies = querySnapToMap(companiesQS) ?? {};

  const [moderatorsQS, loadingModerators, errorModerators] = useCollectionOnce(
    companySelected
      ? query(
          buildCollectionRef(getUsersCol()),
          where('company', '==', companySelected),
          where('moderator', '==', true)
        )
      : undefined
  );
  const isCountIdLicense = state.licenseType === LicenseType.COUNTID;
  const moderators = querySnapToMap(moderatorsQS) ?? {};

  const restrictBeforeChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();

    const allowedChars = '[a-zA-Z0-9\n-]';
    const modValue = e.target.value
      .split('')
      .filter((c) => c.match(allowedChars))
      .join('');
    setState({ [e.target.name]: modValue });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ [e.target.name]: e.target.checked });
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;

    const changeEffects = {} as Partial<State>;
    if (name === 'licenseType') {
      changeEffects.useTestsLicense = value === LicenseType.FLYID_PILOT ? true : false;
    }

    setState({ [name]: value, ...changeEffects });
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState({ [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const { droneSerials } = state;
    const str = droneSerials.toString();
    const serials = str.split(/\r?\n/);

    const licenseData: createLicensesParams = {
      company: companySelected,
      owner: state.owner,
      licenseType: state.licenseType,
      description: state.description,
      expDate: state.expDate.format('DD/MM/YYYY'),
      hasMatrixScan: state.hasMatrixScan,
      scanditApiKey: state.scanditApiKey,
      useTestsLicense: state.useTestsLicense,
      maxDevices: isCountIdLicense ? state.maxDevices : undefined,
      spotOnly: state.spotOnly,
      droneSerials: isCountIdLicense ? undefined : serials
    };

    dispatch(
      updateUi({
        dialog: new MyDialogState({
          title: $t({ id: 'admin.addLicenseConfTitle' }),
          message: $t({ id: 'admin.addLicenseConfMsg' }),
          show: true
        }).setConfirmAction(Actions.CREATE_LICENSES, licenseData)
      })
    );
  };

  return (
    <>
      {!errorCompanies || !errorModerators ? (
        <Container className={classes.container}>
          {!loadingCompanies ? (
            <div className={classes.titleContainer}>
              <Typography variant="h4" sx={text.title}>
                {$t({ id: 'admin.addLicense' })}
              </Typography>
              <Typography variant="subtitle1" sx={text.subtitle}>
                {$t({ id: 'admin.addLicenseSubtitle' })}
              </Typography>
            </div>
          ) : (
            <div className={classes.titleContainer}>
              <Skeleton variant="text" height={spacing(8)} animation="wave" />
              <Skeleton variant="text" height={spacing(4)} animation="wave" />
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} className={classes.mainGrid}>
              {/* Company selector */}
              <Grid item xs={12} md={10}>
                {!loadingCompanies ? (
                  <FormControl fullWidth>
                    <InputLabel id="company-select-label">{$t({ id: 'admin.company' })}</InputLabel>
                    <Select
                      required
                      labelId="company-select-label"
                      id="company-select"
                      name="company"
                      onChange={(e) => setCompanySelected(e.target.value)}
                      value={companySelected ?? ''}
                      input={<OutlinedInput label={$t({ id: 'admin.company' })} />}
                      MenuProps={select.getMenuProps()}
                    >
                      {!!companies ? (
                        Object.keys(companies).map((company, index) => {
                          return (
                            <MenuItem key={index} value={company}>
                              {company}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem value={''}>
                          <Typography variant="body1">{$t({ id: 'none' })}</Typography>
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                ) : (
                  <Skeleton variant="rounded" height={spacing(7)} animation="wave" />
                )}
              </Grid>

              {/* Owner selector */}
              <Grid item xs={12} md={10}>
                {!loadingCompanies ? (
                  <FormControl fullWidth>
                    <InputLabel id="moderator-select-label">{$t({ id: 'moderator' })}</InputLabel>
                    <Select
                      labelId="moderator-select-label"
                      id="moderator-select"
                      required
                      name="owner"
                      disabled={!companySelected}
                      onChange={handleSelectChange}
                      value={state.owner ?? ''}
                      input={<OutlinedInput label={$t({ id: 'moderator' })} />}
                      MenuProps={select.getMenuProps()}
                    >
                      {!loadingModerators && !!moderators ? (
                        Object.entries(moderators).map(([uid, userData], index) => {
                          return (
                            <MenuItem key={index} value={uid}>
                              {userData.firstName} {userData.lastName} ({uid})
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem value={''} disabled>
                          <Typography variant="body1">{$t({ id: 'none' })}</Typography>
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                ) : (
                  <Skeleton variant="rounded" height={spacing(7)} animation="wave" />
                )}
              </Grid>

              {/* License type selector */}
              <Grid item xs={12} md={10}>
                {!loadingCompanies ? (
                  <FormControl fullWidth>
                    <InputLabel id="license-type-select-label">
                      {$t({ id: 'admin.licenseType' })}
                    </InputLabel>
                    <Select
                      labelId="license-type-select-label"
                      id="license-type-select"
                      required
                      name="licenseType"
                      disabled={!companySelected}
                      onChange={handleSelectChange}
                      value={state.licenseType}
                      input={<OutlinedInput label={$t({ id: 'admin.licenseType' })} />}
                      MenuProps={select.getMenuProps()}
                    >
                      {Object.values(LicenseType).map((licenseType, index) => (
                        <MenuItem key={index} value={licenseType}>
                          {HumanReadableLicenseTypes[licenseType]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <Skeleton variant="rounded" height={spacing(7)} animation="wave" />
                )}
              </Grid>

              {/* Description */}
              <Grid item xs={12} md={10}>
                {!loadingCompanies ? (
                  <TextField
                    fullWidth
                    id="description"
                    name="description"
                    value={state.description}
                    type="text"
                    onChange={handleTextChange}
                    label={$t({ id: 'description' })}
                  />
                ) : (
                  <Skeleton variant="rounded" height={spacing(7)} animation="wave" />
                )}
              </Grid>

              {/* Expiration, spotonly and max devices  */}
              <Grid container item spacing={2} xs={12} md={10} alignItems="center">
                {!loadingCompanies ? (
                  <>
                    <Grid item xs={isCountIdLicense ? 5 : 6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                        <DemoContainer components={['DatePicker']} sx={{ mb: 2 }}>
                          <DatePicker
                            label={$t({ id: 'expirationDate' })}
                            value={state.expDate}
                            onChange={(value) => setState({ expDate: value ?? undefined })}
                            disablePast
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={isCountIdLicense ? 4.5 : 6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.spotOnly}
                            name="spotOnly"
                            onChange={handleCheckboxChange}
                          />
                        }
                        label={
                          <Typography variant="body2">{$t({ id: 'spotOnlyLicense' })}</Typography>
                        }
                      />
                      <Tooltip
                        disableTouchListener
                        title={
                          <Typography variant="subtitle2">
                            {$t({ id: 'admin.spotOnlyTooltip' })}
                          </Typography>
                        }
                      >
                        <HelpOutlineIcon fontSize="medium" sx={{ color: 'info.dark' }} />
                      </Tooltip>
                    </Grid>
                    {isCountIdLicense && (
                      <Grid item xs={2.5}>
                        <TextField
                          fullWidth
                          required
                          id="maxDevices"
                          name="maxDevices"
                          type="number"
                          label={$t({ id: 'maxDevices' })}
                          value={state.maxDevices}
                          onChange={handleTextChange}
                        />
                      </Grid>
                    )}
                  </>
                ) : (
                  <>
                    <Grid item xs={6} md={5}>
                      <Skeleton variant="rounded" height={spacing(7)} animation="wave" />
                    </Grid>
                    <Grid item xs={6} md={5}>
                      <Skeleton variant="rounded" height={spacing(7)} animation="wave" />
                    </Grid>
                  </>
                )}
              </Grid>

              {/* Drone serial numbers */}
              {!isCountIdLicense && (
                <Grid container item spacing={2} xs={12} alignContent="flex-start">
                  {!loadingCompanies ? (
                    <>
                      <Grid item xs={11} md={10}>
                        <TextField
                          required
                          multiline
                          fullWidth
                          rows={3}
                          id="droneSerials"
                          name="droneSerials"
                          value={state.droneSerials}
                          onChange={restrictBeforeChange}
                          type="text"
                          label={$t({ id: 'droneSerials' })}
                        />
                      </Grid>
                      <Grid item xs={1} md={2}>
                        <Tooltip
                          disableTouchListener
                          className={classes.tooltip}
                          title={
                            <Typography variant="subtitle2">
                              {$t({ id: 'admin.droneSerialsTooltip' })}
                              <br />
                              {$t({ id: 'admin.droneSerialsTooltip2' })}
                            </Typography>
                          }
                        >
                          <HelpOutlineIcon fontSize="medium" sx={{ color: 'info.dark' }} />
                        </Tooltip>
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12} md={10}>
                      <Skeleton variant="rounded" height={spacing(12)} animation="wave" />
                    </Grid>
                  )}
                </Grid>
              )}

              {/* Use Custom Scandit API Key */}
              <Grid item xs={12} md={10}>
                {!loadingCompanies ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={customScanditApiKey}
                        onChange={(e) => setCustomScanditApiKey(e.target.checked)}
                      />
                    }
                    label={
                      <Typography variant="body2">
                        {$t({ id: 'admin.customScanditApiKey' })}
                      </Typography>
                    }
                  />
                ) : (
                  <Grid item xs={5}>
                    <Skeleton variant="text" height={spacing(5)} animation="wave" />
                  </Grid>
                )}
              </Grid>

              {/* Scandit api key data */}
              <Grid container item spacing={2} xs={12} md={10} alignContent="flex-start">
                {customScanditApiKey ? (
                  <Grid item xs={8}>
                    <TextField
                      fullWidth
                      required
                      id="scanditApiKey"
                      name="scanditApiKey"
                      type="scanditApiKey"
                      label={$t({ id: 'scanditAPIKey' })}
                      value={state.scanditApiKey}
                      onChange={handleTextChange}
                      autoFocus
                    />
                  </Grid>
                ) : (
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={state.licenseType === LicenseType.FLYID_PILOT}
                          checked={state.useTestsLicense}
                          name="useTestsLicense"
                          onChange={handleCheckboxChange}
                        />
                      }
                      label={
                        <Typography variant="body2">
                          {$t({ id: 'admin.useTestsLicense' })}
                        </Typography>
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={customScanditApiKey ? 4 : 6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.hasMatrixScan}
                        name="hasMatrixScan"
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={
                      <Typography variant="body2">{$t({ id: 'admin.hasMatrixScan' })}</Typography>
                    }
                  />
                </Grid>
              </Grid>

              {/* Add button */}
              <Grid item xs={12}>
                {!loadingCompanies ? (
                  <LoadingButton
                    content={$t({ id: 'admin.addLicense' })}
                    type="submit"
                    className={classes.button}
                    isLoading={ui.loadingButton.isLicenseActionLoading}
                  />
                ) : (
                  <Skeleton
                    variant="rounded"
                    height={spacing(5)}
                    width={spacing(25)}
                    animation="wave"
                  />
                )}
              </Grid>
            </Grid>
          </form>
        </Container>
      ) : (
        <>
          <Typography variant="body1">An error occurred: {errorCompanies.message}</Typography>
          <Typography variant="body1">An error occurred: {errorModerators.message}</Typography>
        </>
      )}
    </>
  );
};

export default AddLicense;
