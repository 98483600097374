// Components
import { Route, Switch } from 'react-router-dom';

// Pages
import ProtectedRoute from './components/utils/ProtectedRoute';

import Main from './components/dashboard/Main';
import Login from './components/auth/Login';
import AddCompany from './components/company/AddCompany';
import AddLicenses from './components/license/AddLicenses';
import ReplaceFlyidLicense from './components/license/ReplaceFlyidLicense';
import ManageLicenses from './components/dashboard/ManageLicenses';
import EditLicense from './components/license/EditLicense';
import CriticalLicenses from './components/license/CriticalLicenses';
import ManageUsers from './components/dashboard/ManageUsers';
import AddUser from './components/user/AddUser';
import ReplaceModerator from './components/user/ReplaceModerator';
import UserProfile from './components/user/UserProfile';
import ChangePassword from './components/user/ChangePassword';

const Routes: React.FC = () => {
  return (
    <Switch>
      {/* Auth Routes */}
      <Route path="/login" component={Login} />
      {/* User Routes */}
      <ProtectedRoute adminOnly path="/manageUsers" component={ManageUsers} />
      <ProtectedRoute adminOnly path="/addUser" component={AddUser} />
      <ProtectedRoute adminOnly exact path="/profile/:uid" component={UserProfile} />
      <ProtectedRoute adminOnly path="/changepw" component={ChangePassword} />
      <ProtectedRoute adminOnly exact path="/replaceModerator/:uid" component={ReplaceModerator} />
      {/* Company Routes */}
      <ProtectedRoute adminOnly path="/addCompany" component={AddCompany} />
      {/* Licenses Routes */}
      <ProtectedRoute adminOnly path="/manageLicenses" component={ManageLicenses} />
      <ProtectedRoute adminOnly path="/criticalLicenses" component={CriticalLicenses} />
      <ProtectedRoute adminOnly path="/addLicenses" component={AddLicenses} />
      <ProtectedRoute adminOnly path="/replaceFlyidLicense" component={ReplaceFlyidLicense} />
      <ProtectedRoute adminOnly exact path="/license/:licenseId" component={EditLicense} />
      {/* Main */}
      <ProtectedRoute exact={false} path="/" component={Main} />
    </Switch>
  );
};

export default Routes;
