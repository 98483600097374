import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Checkbox,
  Container,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Skeleton,
  Tooltip,
  Typography
} from '@mui/material';
import dayjs from 'dayjs';
import { query, where } from 'firebase/firestore';
import { getCompaniesCol, getOurLicensesCol } from 'flyid-core/dist/Util/database';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { buildCollectionRef, querySnapToMap } from 'src/firebase/firestore';
import { useAppDispatch } from 'src/hooks/reduxHooks';
import { Actions } from 'src/redux/actions/actionTypes';
import { MyDialogState, updateUi } from 'src/redux/reducers/uiReducer';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { useStoredState } from 'src/util/web';

const useStyles = appMakeStyles(({ resizableContainer, spacing }) => ({
  container: {
    ...resizableContainer(2),
    marginLeft: 0,
    maxWidth: '900px'
  },
  mainGrid: {
    minWidth: '650px'
  },
  titleContainer: {
    marginBottom: spacing(3),
    maxWidth: '900px'
  },
  margin: {
    marginBottom: spacing(1.5)
  },

  listItem: {
    paddingRight: spacing(15)
  },
  itemText: {
    margin: spacing(1, 1)
  }
}));

const ManageLicenses: React.FC = () => {
  const classes = useStyles();
  const { spacing, text } = useAppTheme();
  const { $t } = useIntl();
  const dispatch = useAppDispatch();

  const [companySelected, setCompanySelected] = useStoredState<string>('companySelect', '');
  const [selection, setSelection] = useState({});

  const [companiesQS, loadingCompanies, errorCompanies] = useCollection(
    buildCollectionRef(getCompaniesCol())
  );
  const companies = querySnapToMap(companiesQS) ?? {};

  const [licensesQS, loadingLicenses, errorLicenses] = useCollection(
    companySelected
      ? query(buildCollectionRef(getOurLicensesCol()), where('company', '==', companySelected))
      : undefined
  );
  const licenses = querySnapToMap(licensesQS) ?? {};

  useEffect(() => clearSelection(), [licensesQS]);

  const handleCompanyChange = (e: SelectChangeEvent<string>) => {
    setCompanySelected(e.target.value);
  };

  const handleToggleCheckbox =
    (licenseId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelection((oldState) => ({ ...oldState, [licenseId]: event.target.checked }));
    };

  const hasSelection = Object.values(selection).some((e) => e === true);

  const clearSelection = () => setSelection({});

  const showRemoveLicenseDialogConfirmation = () => {
    if (!hasSelection) return;

    const licenseIds = [
      ...Object.entries(selection)
        .filter(([, v]) => !!v)
        .map(([k]) => k)
    ];

    dispatch(
      updateUi({
        dialog: new MyDialogState({
          title: $t({ id: 'admin.remLicConfTitle' }),
          message: $t({ id: 'admin.remLicConfMsg' }),
          useCheckbox: true,
          checkboxState: false,
          checkboxMessage: <b>{$t({ id: 'admin.remLicConfCheckboxMsg' })}</b>,
          show: true
        }).setConfirmAction(Actions.REMOVE_FLYID_LICENSE, { licenses: licenseIds })
      })
    );
  };

  return (
    <>
      {!errorCompanies || !errorLicenses ? (
        <Container className={classes.container}>
          {!loadingCompanies && !!companies ? (
            <div className={classes.titleContainer}>
              <Typography variant="h4" sx={text.title}>
                {$t({ id: 'nav.managelicenses' })}
              </Typography>
              <Typography variant="subtitle1" sx={text.subtitle}>
                {$t({ id: 'admin.manageLicensesSubtitle' })}
              </Typography>
            </div>
          ) : (
            <div className={classes.margin}>
              <Skeleton variant="text" height={spacing(10)} animation="wave" />
              <Skeleton variant="text" height={spacing(5)} animation="wave" />
            </div>
          )}

          <Grid container spacing={2} className={classes.mainGrid}>
            <Grid item xs={10}>
              {!loadingCompanies && !!companies ? (
                <FormControl fullWidth>
                  <InputLabel id="company-select-label">{$t({ id: 'admin.company' })}</InputLabel>
                  <Select
                    required
                    labelId="company-select-label"
                    id="company-select"
                    name="company"
                    value={companySelected}
                    onChange={handleCompanyChange}
                    input={<OutlinedInput label={$t({ id: 'admin.company' })} />}
                  >
                    <MenuItem value={''}>
                      <Typography variant="body1">{$t({ id: 'none' })}</Typography>
                    </MenuItem>
                    {Object.keys(companies).map((value, index) => (
                      <MenuItem key={index} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <Skeleton variant="rounded" animation="wave" height={spacing(7)} />
              )}
            </Grid>

            <Grid item xs={12}>
              <List
                subheader={
                  <ListSubheader sx={{ backgroundColor: 'background.default' }}>
                    {!loadingLicenses && companySelected ? (
                      $t({ id: 'licenses' })
                    ) : !!companySelected ? (
                      <Skeleton
                        variant="text"
                        width={spacing(20)}
                        height={spacing(4)}
                        animation="wave"
                        className={classes.margin}
                      />
                    ) : null}
                  </ListSubheader>
                }
              >
                {!loadingLicenses ? (
                  !isEmpty(licenses) ? (
                    Object.entries(licenses).map(([license, licenseData]) => {
                      const { description, expDate } = licenseData;
                      const parseDate = dayjs(expDate).format('DD/MM/YYYY');

                      return (
                        <ListItemButton
                          key={license}
                          alignItems="flex-start"
                          className={classes.listItem}
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              onChange={handleToggleCheckbox(license)}
                              checked={!!selection[license]}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={license}
                            className={classes.itemText}
                            primary={`${license} - ${description}`}
                            secondary={$t({ id: 'admin.manLicExpDate' }, { expDate: parseDate })}
                          />
                          <ListItemSecondaryAction>
                            <Tooltip title={$t({ id: 'edit' })}>
                              <IconButton
                                component={Link}
                                to={`/license/${license}`}
                                edge="start"
                                aria-label={$t({ id: 'edit' })}
                                sx={{ color: 'info.main' }}
                                size="large"
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          </ListItemSecondaryAction>
                        </ListItemButton>
                      );
                    })
                  ) : companySelected ? (
                    <ListItem
                      key="emptyLicenses"
                      alignItems="flex-start"
                      className={classes.listItem}
                    >
                      <ListItemText
                        id="emptyLicenses"
                        className={classes.itemText}
                        primary={$t({ id: 'admin.manLicNoLicenses' })}
                      />
                    </ListItem>
                  ) : null
                ) : (
                  Array.from({ length: 6 }).map((_, index) => (
                    <Box key={`skeleton-${index}`}>
                      <Skeleton
                        variant="rounded"
                        height={spacing(8)}
                        width={spacing(85)}
                        animation="wave"
                        className={classes.margin}
                      />
                    </Box>
                  ))
                )}

                <Grid container item xs={12} justifyContent="space-between">
                  <Grid item xs={6}>
                    <ListItem>
                      <Fab
                        variant="extended"
                        size="medium"
                        color="secondary"
                        aria-label="addLicenses"
                        component={Link}
                        to="/addLicenses"
                      >
                        <AddCircleIcon sx={{ mr: 1 }} />
                        {$t({ id: 'admin.addLicenses' })}
                      </Fab>
                    </ListItem>
                  </Grid>

                  <Grid item xs={6} textAlign="end">
                    {!hasSelection ? (
                      <ListItem disabled>
                        <Tooltip
                          disableTouchListener
                          placement="right-start"
                          title={
                            <Typography variant="subtitle2">
                              {$t({ id: 'admin.remLicButtonTooltip' })}
                            </Typography>
                          }
                        >
                          <Fab
                            variant="extended"
                            size="medium"
                            color="error"
                            aria-label="removeLicenses"
                          >
                            <DeleteSweepIcon sx={{ mr: 1 }} />
                            {$t({ id: 'admin.removeLicenses' })}
                          </Fab>
                        </Tooltip>
                      </ListItem>
                    ) : (
                      <ListItem>
                        <Fab
                          variant="extended"
                          size="medium"
                          color="error"
                          aria-label="removeLicenses"
                          onClick={() => showRemoveLicenseDialogConfirmation()}
                        >
                          <DeleteSweepIcon sx={{ mr: 1 }} />
                          {$t({ id: 'admin.removeLicenses' })}
                        </Fab>
                      </ListItem>
                    )}
                  </Grid>
                </Grid>
              </List>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <>
          <Typography variant="body1">An error occurred: {errorCompanies.message}</Typography>
          <Typography variant="body1">An error occurred: {errorLicenses.message}</Typography>
        </>
      )}
    </>
  );
};

export default ManageLicenses;
