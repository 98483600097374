import { SetupCompanyParams } from './companyActions';
import {
  createLicensesParams,
  EditFlyidLicenseParams,
  removeFlyidLicensesParams,
  replaceFlyidLicenseParams
} from './licenseActions';
import {
  AddUserParams,
  EditUserParams,
  LoginParams,
  LogoutParams,
  RemoveUserParams,
  ReplaceModeratorParams,
  ResetUserPasswordParams
} from './userActions';

export enum Actions {
  NONE = 'NONE',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  ADD_USER = 'ADD_USER',
  EDIT_USER = 'EDIT_USER',
  REMOVE_USER = 'REMOVE_USER',
  RESET_USER_PASSWORD = 'RESET_USER_PASSWORD',
  REPLACE_MODERATOR = 'REPLACE_MODERATOR',
  SETUP_COMPANY = 'SETUP_COMPANY',
  CREATE_LICENSES = 'CREATE_LICENSE',
  EDIT_FLYID_LICENSE = 'EDIT_FLYID_LICENSE',
  REPLACE_FLYID_LICENSE = 'REPLACE_FLYID_LICENSE',
  REMOVE_FLYID_LICENSE = 'REMOVE_FLYID_LICENSE'
}

export type ParameterMap = {
  NONE: undefined | null;
  LOGIN: LoginParams;
  LOGOUT: LogoutParams;
  ADD_USER: AddUserParams;
  EDIT_USER: EditUserParams;
  REMOVE_USER: RemoveUserParams;
  RESET_USER_PASSWORD: ResetUserPasswordParams;
  REPLACE_MODERATOR: ReplaceModeratorParams;
  SETUP_COMPANY: SetupCompanyParams;
  CREATE_LICENSE: createLicensesParams;
  EDIT_FLYID_LICENSE: EditFlyidLicenseParams;
  REPLACE_FLYID_LICENSE: replaceFlyidLicenseParams;
  REMOVE_FLYID_LICENSE: removeFlyidLicensesParams;
};
