import { useRef } from 'react';
import useOnceEffect from './useOnceEffect';

export type ValueFilter = "falsy" | "nulli" | "none"

/**
 * This hook will memorize the first provided {@link value} and won't change after that.
 * 
 * Depending on value of {@link filter}, it decides whether to accept or not the input value 
 * (default is 'falsy'):
 * 
 * When:
 * - 'falsy': Ignores any falsy value
 * - 'nulli': Ignores any nulli value (null | undefined), accepting falsy (0, "", etc)
 * - 'none': Accepts all values, including falsy and nulli
 *  
 */
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const useFirst = <T extends unknown>(value: T, filter: ValueFilter = "falsy"): T | undefined => {
    const ref = useRef<T>();
    useOnceEffect(() => {
        if (filter === 'falsy' && !value) return [false];
        // eslint-disable-next-line eqeqeq
        else if (filter === 'nulli' && value == null) return [false];
        // 'none' accepts all values
        console.log("(first) setting value from", ref.current, "to", value);
        ref.current = value;
        return [true];
    }, [value]);
    return ref.current;
};

export default useFirst;
