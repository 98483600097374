import React from 'react';
import ReactDom from 'react-dom';
// eslint-disable-next-line import/no-unassigned-import
import './App.css';
// Redux
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import Routes from './routes';
//Internationalization
import axios from 'axios';
import { Router } from 'react-router-dom';
import environment from 'src/environment';
import LandingLoading from './components/layout/LandingLoading';
import NavBar from './components/layout/NavBar';
import IntlAndTheme from './components/utils/IntlAndTheme';
import { initFirebase } from './firebase/firebase';
import { setupFirebaseListeners } from './firebase/listeners';
import history from './history';
import initStore from './redux/store';
import GlobalFeedback from './components/layout/GlobalFeedback';

// Init axios base url with server url
axios.defaults.baseURL = `${environment.serverApi}`;

// Make sure firebase is properly initialized
const firebaseData = initFirebase();

export const store = initStore(firebaseData);

setupFirebaseListeners({ store, ...firebaseData });

ReactDom.render(
  <React.StrictMode>
    {/* Redux provider */}
    <Provider store={store}>
      {/* Translation and theme */}
      <IntlAndTheme>
        {/* First load should land into this page */}
        <LandingLoading>
          {/* Router with history API */}
          <Router history={history}>
            {/* Set up React container */}
            <div className="App">
              {/* Navbar with top toolbar and sidebar */}
              <NavBar>
                {/* Actual content in routes switch */}
                <Routes />
              </NavBar>
              {/* Handle various UI feedback mechanism */}
              <GlobalFeedback />
            </div>
          </Router>
        </LandingLoading>
      </IntlAndTheme>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
