import pako from 'pako';
import * as xlsx from 'xlsx';
import { TranslatableError } from './../locale';
import { TableOutputDateFormat } from 'flyid-core/dist/Common/const';

export const ReaderStatus = {
  SUCCESS: 0,
  ABORTED: 1,
  ERROR: 2
};

export type DecodedFile = {
  name: string;
  size: number;
  type: string;
  arrayBuffer: ArrayBuffer;
};

export type AwaitReaderReturnType = [status: number, data: string | ArrayBuffer | null];
async function awaitReaderFor(
  readFun: (reader: FileReader) => void
): Promise<AwaitReaderReturnType> {
  const reader = new FileReader();

  return await new Promise((resolve) => {
    reader.onabort = () => resolve([ReaderStatus.ABORTED, null]);
    reader.onerror = () => resolve([ReaderStatus.ERROR, null]);
    reader.onloadend = () => {
      const data = reader.result;
      resolve([data ? ReaderStatus.SUCCESS : ReaderStatus.ERROR, data]);
    };
    // Start reading
    readFun(reader);
  });
}

export const readAsDataUrl = async (urlData: Blob) => {
  return awaitReaderFor((reader) => reader.readAsDataURL(urlData));
};

export const readAsArrayBuffer = async (bufferData: Blob) => {
  return awaitReaderFor((reader) => reader.readAsArrayBuffer(bufferData));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SheetData = { [column: string]: string };
export type SheetParserResult = {
  sheetData: SheetData[];
  orderedColumns: string[];
};
export type TableParserResult = { [sheetName: string]: SheetParserResult };
/**
 * Parses table data into json format
 *
 * @param {*} inputFileData decoded file from FileReader
 */
export function parseTableInputFile(
  inputFileData: DecodedFile | Uint8Array | string
): TableParserResult {
  console.log(inputFileData);
  let workbook: xlsx.WorkBook;
  if (isByteArray(inputFileData)) {
    workbook = xlsx.read(inputFileData, { type: 'array' });
  } else if (typeof inputFileData === 'string') {
    workbook = xlsx.read(inputFileData, { type: 'string' });
  } else {
    workbook = xlsx.read(inputFileData.arrayBuffer, { type: 'array' });
  }

  // Parse input file data
  if (!workbook) {
    throw new TranslatableError('failedParsingInputTableFileType');
  }

  const tableParserResult: TableParserResult = {};
  for (const sheetName of workbook.SheetNames) {
    // Transform sheet to json format
    const workSheet = workbook.Sheets[sheetName];
    const sheetData = xlsx.utils.sheet_to_json<SheetData>(workSheet, {
      defval: '',
      raw: true,
      dateNF: TableOutputDateFormat
    });
    if (!sheetData) {
      throw new TranslatableError('failedParsingInputTableFile', { sheetName });
    }

    sheetData.forEach((line) => {
      // Guarantee data is in string format
      Object.keys(line).forEach((key) => (line[key] = String(line[key])));
    });

    tableParserResult[sheetName] = {
      sheetData,
      orderedColumns: Object.keys(sheetData[0]).filter((c) => !c.match(/^__EMPTY_*\d*/))
    };
  }
  return tableParserResult;
}

export const getDeflated = (data: pako.Data) => Buffer.from(pako.deflate(data)).toString('base64');

export const getInflated = (data: string) =>
  pako.inflate(Buffer.from(data, 'base64'), { to: 'string' });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isByteArray = (data: any): data is Uint8Array =>
  Object.prototype.toString.call(data) === '[object Uint8Array]';
