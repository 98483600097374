import { UserPublic } from 'flyid-core/dist/Database/Models/User';
import { AppState } from '../store';

export const selectProfile = (state: AppState) =>
  state.user.isLoaded ? (state.user.profile as UserPublic) : undefined;

export const selectUserProfiles = (state: AppState) => state.firestore.userProfiles;

export const selectUserProfilePics = (state: AppState) => state.user.profilePics;

export const selectUserProfileThumbs = (state: AppState) => state.user.profilePicThumbs;
