import { MaybeKeyUserPublic, UserPublic } from 'flyid-core/dist/Database/Models/User';
import { MyUserStateData } from 'src/redux/types/userTypes';

export const isModeratorClaims = (user?: MyUserStateData) => {
  return user?.claims?.moderator === true;
};

export const isAdminProf = (profile?: MaybeKeyUserPublic) => {
  return profile?.admin === true;
};

export const isKeyUserProf = (profile?: MaybeKeyUserPublic) => {
  return profile?.keyUser === true;
};

export const isModeratorProf = (profile?: MaybeKeyUserPublic) => {
  return profile?.moderator === true;
};

export const isAssistantProf = (profile?: MaybeKeyUserPublic) => {
  return profile?.assistant === true;
};

export const isPilotProf = (profile?: MaybeKeyUserPublic) => {
  return profile?.pilot === true;
};

export const hasApiKeys = (user?: MyUserStateData) =>
  (user?.claims?.ownedAPIKeys as string[])?.length > 0;
