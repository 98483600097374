import {
  CollectionReference, FieldPath, getDocs, Query,
  query,
  QueryDocumentSnapshot, where
} from 'firebase/firestore';

// Firestore utils
export function getSlicedWhereArrayOperandQuery<T>(
  collectionRefOrQuery: CollectionReference<T> | Query<T>,
  propertyName: string | FieldPath,
  whereFilterOp: 'in' | 'not-in' | 'array-contains-any',
  array: (string | boolean | number)[]
) {
  const slices = Math.floor(array.length / 10) + (array.length % 10 > 0 ? 1 : 0);

  const queries: Array<Query<T>> = [];
  for (let i = 0; i < slices; i++) {
    queries.push(
      query(
        collectionRefOrQuery,
        where(propertyName, whereFilterOp, array.slice(i * 10, (i + 1) * 10))
      )
    );
  }

  return queries;
}

export const getAndAccumulateQueries = async <T>(queries: Query<T>[]) => {
  return await Promise.all(
    queries.map((_query) =>
      getDocs(_query).then((querySnap) =>
        !querySnap.empty
          ? querySnap.docs
            .map((doc) => (doc.exists() ? doc : undefined))
            .filter((d): d is QueryDocumentSnapshot<T> => Boolean(d))
          : undefined
      ).catch(err => console.error(err, `Failed fetching query!`))
    )
  ).then((docs) => docs.filter((d): d is QueryDocumentSnapshot<T>[] => Boolean(d)).flat());
};
