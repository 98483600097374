import { immerable } from 'immer';

export type MaybeImmerable<T = unknown> = T & {
  [immerable]?: boolean;
};

export class LoadingButtonState {
  [immerable]? = true;
  isLoginLoading = false;
  isCompanyActionLoading = false;
  isLicenseActionLoading = false;
  isUserActionLoading = false;
}
