import {
  collection,
  CollectionReference,
  doc,
  DocumentData,
  DocumentReference,
  QuerySnapshot
} from 'firebase/firestore';
import { FirestoreReferenceData } from 'flyid-core/dist/Util/database';
import { MaybeLoaded } from 'src/redux/selectors/types';
import { firestore } from './firebase';

export function buildDocumentRef<T = DocumentData>(
  ref: FirestoreReferenceData<T>
): DocumentReference<T> {
  const _ref = doc(firestore, ref.path);
  return ref.converter ? _ref.withConverter(ref.converter) : (_ref as DocumentReference<T>);
}

export function buildCollectionRef<T = DocumentData>(
  ref: FirestoreReferenceData<T>
): CollectionReference<T> {
  const _ref = collection(firestore, ref.path);
  return ref.converter ? _ref.withConverter(ref.converter) : (_ref as CollectionReference<T>);
}

export function querySnapToMap<T>(querySnap?: QuerySnapshot<T>): MaybeLoaded<{ [id: string]: T }> {
  return querySnap?.docs?.reduce((obj, docSnap) => {
    obj[docSnap.id] = docSnap.data();
    return obj;
  }, {});
}
