import authAxios from 'src/util/axios';
import { getSnackbar } from 'src/util/helpers/server';
import { updateUi } from '../reducers/uiReducer';
import { ThunkActionType } from '../store';
import { AdminCallPayload } from '../types/common';
import history from 'src/history';

export type SetupCompanyParams = {
  company: string;
  exhibitionName: string;
  timezone?: string;
  moderator?: {
    email: string;
    firstName: string;
    lastName: string;
    employeeId?: string;
  };
  sendEmail?: boolean;
  featureLocks?: string[];
};
export const setupCompany =
  (data: SetupCompanyParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(updateUi({ loadingButton: { isCompanyActionLoading: true } }));

    const { user } = getState();
    const _data: AdminCallPayload = {
      posArgs: [data.company, data.exhibitionName],
      listArgs: {
        timezone: data.timezone ? [data.timezone] : undefined,
        moderator: data.moderator ? Object.values(data.moderator) : undefined,
        sendEmail: [data.sendEmail ?? false],
        featureLocks: data.featureLocks,
        // Grants company access to this user if is key user as well
        grantAccessToKeyUser: user.claims.keyUser ? [getAuth().currentUser!.uid] : undefined
      }
    };

    authAxios(getAuth(), getState(), {
      url: `/admin/execute`,
      method: 'post',
      data: _data,
      params: {
        command: 'setupCompany'
      }
    })
      .then((res) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(res),
            loadingButton: {
              isCompanyActionLoading: false
            }
          })
        );
        history.push('/');
      })
      .catch((err: Error) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(err),
            loadingButton: {
              isCompanyActionLoading: false
            }
          })
        );
      });
  };
