import AddCircleIcon from '@mui/icons-material/AddCircle';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Avatar,
  Box,
  Collapse,
  Container,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Skeleton,
  Tooltip,
  Typography
} from '@mui/material';
import { query, where } from 'firebase/firestore';
import { getCompaniesCol, getKeyUsersCol, getUsersCol } from 'flyid-core/dist/Util/database';
import { isEmpty } from 'lodash';
import React, { Fragment, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { buildCollectionRef, querySnapToMap } from 'src/firebase/firestore';
import { useAppDispatch } from 'src/hooks/reduxHooks';
import { Actions } from 'src/redux/actions/actionTypes';
import { MyDialogState, updateUi } from 'src/redux/reducers/uiReducer';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { useStoredState } from 'src/util/web';

const useStyles = appMakeStyles(({ resizableContainer, spacing }) => ({
  container: {
    ...resizableContainer(2),
    marginLeft: 0,
    maxWidth: '950px'
  },
  mainGrid: {
    minWidth: '650px'
  },
  titleContainer: {
    marginBottom: spacing(3),
    maxWidth: '950px'
  },
  margin: {
    marginBottom: spacing(1.5)
  },
  itemText: {
    margin: spacing(1, 1),
    marginLeft: spacing(2)
  },
  profileImage: {
    width: spacing(8),
    height: spacing(8)
  }
}));

//TODO: implement profile picture fetching

const ManageUsers: React.FC = () => {
  const classes = useStyles();
  const { spacing, text, palette } = useAppTheme();
  const { $t } = useIntl();
  const dispatch = useAppDispatch();

  const [companySelected, setCompanySelected] = useStoredState<string>('companySelect', '');

  const [openParent, setOpenParent] = useState<string | null>(null);

  const [companiesQS, loadingCompanies, errorCompanies] = useCollection(
    buildCollectionRef(getCompaniesCol())
  );
  const companies = querySnapToMap(companiesQS) ?? {};

  const [usersQS, loadingUsers, errorUsers] = useCollection(
    companySelected
      ? query(buildCollectionRef(getUsersCol()), where('company', '==', companySelected))
      : undefined
  );
  const users = querySnapToMap(usersQS) ?? {};

  const [keyUsersQS, loadingkeyUsers, errorkeyUsers] = useCollection(
    companySelected
      ? query(
          buildCollectionRef(getKeyUsersCol()),
          where('company', 'array-contains', companySelected)
        )
      : undefined
  );
  const keyUsers = querySnapToMap(keyUsersQS) ?? {};

  const handleCompanyChange = (e: SelectChangeEvent<string>) => {
    setCompanySelected(e.target.value);
  };

  const handleOpenParentChange = (parent: string) =>
    setOpenParent((prevState) => {
      if (prevState !== null && parent === prevState) {
        return null;
      }
      return parent;
    });

  const showRemoveUserDialogConfirmation = (uid: string) => {
    const userData = users?.[uid];
    if (!userData) {
      return;
    }

    const company = companySelected;

    dispatch(
      updateUi({
        dialog: new MyDialogState({
          title: $t({ id: 'manUsr.remUserTitle' }),
          message: $t(
            { id: 'manUsr.remUserMsg' },
            {
              name: <b key="mub0">{`${userData.firstName} ${userData.lastName}`}</b>,
              nl: <br key="munl0" />
            }
          ),
          show: true
        }).setConfirmAction(Actions.REMOVE_USER, { uid, company })
      })
    );
  };

  return (
    <>
      {!errorCompanies || !errorUsers || !errorkeyUsers ? (
        <Container className={classes.container}>
          {!loadingCompanies && !!companies ? (
            <div className={classes.titleContainer}>
              <Typography variant="h4" sx={text.title}>
                {$t({ id: 'nav.manageusers' })}
              </Typography>
              <Typography variant="subtitle1" sx={text.subtitle}>
                {$t({ id: 'admin.manUsersSubtitle' })}
              </Typography>
            </div>
          ) : (
            <div className={classes.titleContainer}>
              <Skeleton variant="text" height={spacing(10)} animation="wave" />
              <Skeleton variant="text" height={spacing(5)} animation="wave" />
            </div>
          )}

          <Grid container className={classes.mainGrid}>
            <Grid item xs={10}>
              {!loadingCompanies && !!companies ? (
                <FormControl fullWidth>
                  <InputLabel id="company-select-label">{$t({ id: 'admin.company' })}</InputLabel>
                  <Select
                    required
                    labelId="company-select-label"
                    id="company-select"
                    name="company"
                    value={companySelected}
                    onChange={handleCompanyChange}
                    input={<OutlinedInput label={$t({ id: 'admin.company' })} />}
                  >
                    <MenuItem value={''}>
                      <Typography variant="body1">{$t({ id: 'none' })}</Typography>
                    </MenuItem>
                    {Object.keys(companies).map((value, index) => (
                      <MenuItem key={index} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  height={spacing(8)}
                  className={classes.margin}
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <List
                subheader={
                  <ListSubheader sx={{ backgroundColor: 'background.default' }}>
                    {!loadingCompanies && companySelected ? (
                      $t({ id: 'manUsr.users' })
                    ) : !!companySelected ? (
                      <Skeleton
                        variant="text"
                        width={spacing(20)}
                        height={spacing(4)}
                        animation="wave"
                        className={classes.margin}
                      />
                    ) : null}
                  </ListSubheader>
                }
              >
                {!loadingUsers ? (
                  !isEmpty(users) ? (
                    Object.entries(users).map(([uid, userProfile]) => {
                      const { firstName, lastName, authDomains, pilot, assistant, moderator } =
                        userProfile;
                      const domains = authDomains.join(', ');

                      const userPermissions = [
                        moderator && $t({ id: 'moderator' }),
                        pilot && $t({ id: 'pilot' }),
                        assistant && $t({ id: 'assistant' })
                      ].filter(Boolean);

                      const permissions = userPermissions.join(', ');

                      if (moderator) {
                        return (
                          <Fragment key={uid}>
                            <ListItemButton
                              alignItems="flex-start"
                              className={classes.listItem}
                              onClick={() => handleOpenParentChange(uid)}
                            >
                              <ListItemAvatar>
                                <Avatar
                                  className={classes.profileImage}
                                  alt={$t({ id: 'altUserProfileImage' })}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                id={uid}
                                className={classes.itemText}
                                primary={`${firstName} ${lastName} (${uid})`}
                                secondary={$t(
                                  { id: 'admin.manUsersListItem1' },
                                  {
                                    permissions: permissions,
                                    nl: <br key={`${uid}nl`} />,
                                    authDomains: domains
                                  }
                                )}
                              />
                              <ListItemSecondaryAction>
                                <Box
                                  sx={{
                                    top: 0,
                                    right: 0,
                                    ml: spacing(6)
                                  }}
                                >
                                  {uid === openParent ? (
                                    <ExpandLess sx={{ color: palette.primary.main }} />
                                  ) : (
                                    <ExpandMore sx={{ color: palette.primary.main }} />
                                  )}
                                </Box>
                                <Tooltip title={$t({ id: 'view' })}>
                                  <IconButton
                                    component={Link}
                                    to={`/profile/${uid}`}
                                    edge="start"
                                    aria-label={$t({ id: 'view' })}
                                    sx={{ color: 'info.main' }}
                                    size="large"
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title={$t({ id: 'replace' })}>
                                  <IconButton
                                    component={Link}
                                    to={`/replaceModerator/${uid}`}
                                    edge="end"
                                    aria-label={$t({ id: 'replace' })}
                                    color="warning"
                                  >
                                    <ChangeCircleIcon fontSize="large" />
                                  </IconButton>
                                </Tooltip>
                              </ListItemSecondaryAction>
                            </ListItemButton>

                            <Collapse in={uid === openParent} timeout="auto" unmountOnExit>
                              {Object.entries(users).map(([childUid, childUserProfile]) => {
                                if (
                                  childUserProfile.parent === uid &&
                                  (childUserProfile.pilot || childUserProfile.assistant)
                                ) {
                                  const { pilot: isPilot, assistant: isAssistant } =
                                    childUserProfile;

                                  const childPermissions = [
                                    isPilot && $t({ id: 'pilot' }),
                                    isAssistant && $t({ id: 'assistant' })
                                  ].filter(Boolean);

                                  return (
                                    <ListItemButton
                                      key={childUid}
                                      alignItems="flex-start"
                                      className={classes.listItem}
                                      sx={{ pl: spacing(7) }}
                                    >
                                      <ListItemAvatar>
                                        <Avatar
                                          className={classes.profileImage}
                                          alt={$t({ id: 'altUserProfileImage' })}
                                        />
                                      </ListItemAvatar>
                                      <ListItemText
                                        id={childUid}
                                        className={classes.itemText}
                                        primary={`${childUserProfile.firstName} ${childUserProfile.lastName} (${childUid})`}
                                        secondary={$t(
                                          { id: 'admin.manUsersListItem1' },
                                          {
                                            permissions: childPermissions.join(', '),
                                            nl: <br key={`${childUid}nl`} />,
                                            authDomains: childUserProfile.authDomains.join(', ')
                                          }
                                        )}
                                      />
                                      <ListItemSecondaryAction>
                                        <Tooltip title={$t({ id: 'view' })}>
                                          <IconButton
                                            component={Link}
                                            to={`/profile/${childUid}`}
                                            edge="start"
                                            aria-label={$t({ id: 'view' })}
                                            sx={{ color: 'info.main', mr: spacing(0.5) }}
                                            size="large"
                                          >
                                            <VisibilityIcon />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title={$t({ id: 'remove' })}>
                                          <IconButton
                                            edge="end"
                                            aria-label={$t({ id: 'remove' })}
                                            sx={{ color: 'error.main' }}
                                            onClick={() =>
                                              showRemoveUserDialogConfirmation(childUid)
                                            }
                                            size="large"
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </ListItemSecondaryAction>
                                    </ListItemButton>
                                  );
                                }
                                return null;
                              })}
                            </Collapse>
                          </Fragment>
                        );
                      }
                      return null;
                    })
                  ) : companySelected ? (
                    <ListItem key="emptyUsers" alignItems="flex-start" className={classes.listItem}>
                      <ListItemText
                        id="emptyUsers"
                        className={classes.itemText}
                        primary={$t({ id: 'manUsr.noUsers' })}
                      />
                    </ListItem>
                  ) : null
                ) : (
                  Array.from({ length: 6 }).map((_, index) => (
                    <Box
                      key={`skeleton-${index}`}
                      sx={{ display: 'flex', alignItems: 'flex-start' }}
                    >
                      <Skeleton
                        variant="circular"
                        height={spacing(8)}
                        width={spacing(9)}
                        animation="wave"
                        className={classes.margin}
                        sx={{ ml: spacing(2), mt: spacing(1.5) }}
                      />
                      <Skeleton
                        variant="rounded"
                        height={spacing(10)}
                        width={spacing(85)}
                        animation="wave"
                        className={classes.margin}
                        sx={{ ml: spacing(2) }}
                      />
                    </Box>
                  ))
                )}

                {!loadingUsers && !loadingkeyUsers && !!keyUsers
                  ? Object.entries(keyUsers).map(([keyUid, keyUserProfile]) => {
                      const { firstName, lastName, keyUser, company } = keyUserProfile;

                      const permissions = keyUser && $t({ id: 'keyUser' });
                      const keyCompanies = company.join(', ');

                      return (
                        <Fragment key={keyUid}>
                          <ListItemButton alignItems="flex-start" className={classes.listItem}>
                            <ListItemAvatar>
                              <Avatar
                                className={classes.profileImage}
                                alt={$t({ id: 'altUserProfileImage' })}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              id={keyUid}
                              className={classes.itemText}
                              primary={`${firstName} ${lastName} (${keyUid})`}
                              secondary={$t(
                                { id: 'admin.manUsersListItem2' },
                                {
                                  permissions: permissions,
                                  nl: <br key={`${keyUid}nl`} />,
                                  companies: keyCompanies
                                }
                              )}
                            />
                            <ListItemSecondaryAction>
                              <div className={classes.icons}>
                                <Tooltip title={$t({ id: 'view' })}>
                                  <IconButton
                                    component={Link}
                                    to={`/profile/${keyUid}`}
                                    edge="start"
                                    aria-label={$t({ id: 'view' })}
                                    sx={{ color: 'info.main' }}
                                    size="large"
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title={$t({ id: 'keyUser' })}>
                                  <IconButton
                                    aria-label={$t({ id: 'keyUser' })}
                                    sx={{ ml: 0.5 }}
                                    color="success"
                                    edge="end"
                                    size="large"
                                  >
                                    <VerifiedUserIcon fontSize="medium" />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </ListItemSecondaryAction>
                          </ListItemButton>
                        </Fragment>
                      );
                    })
                  : null}
                <ListItem>
                  <Fab
                    variant="extended"
                    size="medium"
                    color="secondary"
                    aria-label="addUser"
                    component={Link}
                    to="/addUser"
                    sx={{ mt: spacing(2) }}
                  >
                    <AddCircleIcon sx={{ mr: 1 }} />
                    {$t({ id: 'manUsr.addUsr' })}
                  </Fab>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <>
          <Typography variant="body1">An error occurred: {errorCompanies.message}</Typography>
          <Typography variant="body1">An error occurred: {errorUsers.message}</Typography>
          <Typography variant="body1">An error occurred: {errorkeyUsers.message}</Typography>
        </>
      )}
    </>
  );
};

export default ManageUsers;
