import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FirestoreState,
  SetAPIKeysData,
  SetAuthLicensesData,
  SetAuthProviderData,
  SetUserProfilesData
} from '../types/firestoreTypes';
import type { SetDomainSettingsData } from './../types/firestoreTypes';

const firestoreSlice = createSlice({
  name: 'firestore',
  initialState: new FirestoreState(),
  reducers: {
    // Common data
    setAuthProvider: (state, action: PayloadAction<SetAuthProviderData>) => {
      state.authProviders = Object.assign(state.authProviders ?? {}, {
        areAuthProvidersLoaded: true,
        providers: action.payload
      });
    },
    // Domain data
    setDomainSettings: (state, action: PayloadAction<SetDomainSettingsData>) => {
      state.domainSettings = { ...action.payload };
    },
    // Moderator data actions
    setUserProfiles: (state, action: PayloadAction<SetUserProfilesData>) => {
      state.userProfiles = { ...action.payload };
    },
    setAuthLicenses: (state, action: PayloadAction<SetAuthLicensesData>) => {
      state.authLicenses = { ...action.payload };
    },
    setAPIKeys: (state, action: PayloadAction<SetAPIKeysData>) => {
      state.apiKeys = { ...action.payload };
    }
  }
});

export const { setDomainSettings, setUserProfiles, setAuthLicenses, setAPIKeys, setAuthProvider } =
  firestoreSlice.actions;

export default firestoreSlice.reducer;
