import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Skeleton,
  Typography
} from '@mui/material';
import { query, where } from 'firebase/firestore';
import { getCompaniesCol, getOurLicensesCol } from 'flyid-core/dist/Util/database';
import React, { useState } from 'react';
import { useCollectionOnce } from 'react-firebase-hooks/firestore';
import { useIntl } from 'react-intl';
import { buildCollectionRef, querySnapToMap } from 'src/firebase/firestore';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import useStateReducer from 'src/hooks/useStateReducer';
import { Actions } from 'src/redux/actions/actionTypes';
import { replaceFlyidLicenseParams } from 'src/redux/actions/licenseActions';
import { MyDialogState, updateUi } from 'src/redux/reducers/uiReducer';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import LoadingButton from '../widgets/LoadingButton';
import { useStoredState } from 'src/util/web';

const useStyles = appMakeStyles(({ spacing, resizableContainer }) => ({
  container: {
    ...resizableContainer(2),
    marginLeft: 0,
    maxWidth: '900px'
  },
  mainGrid: {
    minWidth: '650px'
  },
  titleContainer: {
    marginBottom: spacing(4),
    maxWidth: '900px'
  }
}));

const initialReplaceLicenseData = {
  currentLicense: '',
  newLicense: '',
  deleteCurrent: false
};
type State = typeof initialReplaceLicenseData;

const ReplaceFlyidLicense: React.FC = () => {
  const classes = useStyles();
  const { text, spacing } = useAppTheme();
  const { $t } = useIntl();
  const dispatch = useAppDispatch();

  const { ui } = useAppSelector((s) => ({
    ui: s.ui
  }));

  const [state, setState] = useStateReducer<State>(Object.assign({}, initialReplaceLicenseData));

  const [companySelected, setCompanySelected] = useStoredState<string>('companySelect', '');
  
  const [companiesQS, loadingCompanies, errorCompanies] = useCollectionOnce(
    buildCollectionRef(getCompaniesCol())
  );
  const companies = querySnapToMap(companiesQS) ?? {};

  const [licensesQS, loadingLicenses, errorLicenses] = useCollectionOnce(
    companySelected
      ? query(buildCollectionRef(getOurLicensesCol()), where('company', '==', companySelected))
      : undefined
  );
  const licenses = querySnapToMap(licensesQS) ?? {};

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const licenseData: replaceFlyidLicenseParams = {
      currentLicense: state.currentLicense,
      newLicense: state.newLicense,
      deleteCurrent: state.deleteCurrent
    };

    dispatch(
      updateUi({
        dialog: new MyDialogState({
          title: $t({ id: 'admin.replaceLicenseConfTitle' }),
          message: $t(
            { id: 'admin.replaceLicenseConfMsg' },
            {
              currentLicense: (
                <b key={`mdb0${licenseData.currentLicense}`}>{licenseData.currentLicense}</b>
              ),
              newLicense: <b key={`mdb0${licenseData.newLicense}`}>{licenseData.newLicense}</b>
            }
          ),
          show: true
        }).setConfirmAction(Actions.REPLACE_FLYID_LICENSE, licenseData)
      })
    );
  };

  const handleCompanyChange = (e: SelectChangeEvent<string>) => {
    setCompanySelected(e.target.value);
    setState({ ...initialReplaceLicenseData });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ [e.target.name]: e.target.checked });
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    setState({ [e.target.name]: e.target.value });
  };

  return (
    <>
      {!errorCompanies || !errorLicenses ? (
        <Container className={classes.container}>
          {!loadingCompanies && !!companies ? (
            <div className={classes.titleContainer}>
              <Typography variant="h4" sx={text.title}>
                {$t({ id: 'admin.replaceFlyidLicense' })}
              </Typography>
              <Typography variant="subtitle1" sx={text.subtitle}>
                {$t({ id: 'admin.replaceLicenseSubtitle' })}
              </Typography>
            </div>
          ) : (
            <div className={classes.titleContainer}>
              <Skeleton variant="text" height={spacing(10)} animation="wave" />
              <Skeleton variant="text" height={spacing(5)} animation="wave" />
            </div>
          )}

          <form onSubmit={handleSubmit}>
            <Grid container className={classes.mainGrid} spacing={2}>
              <Grid item xs={12} md={10}>
                {!loadingCompanies && !!companies ? (
                  <FormControl fullWidth>
                    <InputLabel id="company-select-label">{$t({ id: 'admin.company' })}</InputLabel>
                    <Select
                      required
                      labelId="company-select-label"
                      id="company-select"
                      name="company"
                      value={companySelected}
                      onChange={handleCompanyChange}
                      input={<OutlinedInput label={$t({ id: 'admin.company' })} />}
                    >
                      <MenuItem value={''} disabled>
                        <Typography variant="body1">{$t({ id: 'none' })}</Typography>
                      </MenuItem>
                      {Object.keys(companies).map((value, index) => {
                        return (
                          <MenuItem key={index} value={value}>
                            {value}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                ) : (
                  <Skeleton variant="rounded" animation="wave" height={spacing(7)} />
                )}
              </Grid>

              <Grid item xs={12} md={10}>
                {!loadingCompanies && !!companies ? (
                  <FormControl fullWidth>
                    <InputLabel id="license-select-label">
                      {$t({ id: 'admin.currentLicense' })}
                    </InputLabel>
                    <Select
                      labelId="crlicense-select-label"
                      id="crlicense-select"
                      required
                      name="currentLicense"
                      onChange={handleSelectChange}
                      value={state.currentLicense}
                      input={<OutlinedInput label={$t({ id: 'admin.currentLicense' })} />}
                    >
                      <MenuItem value={''} disabled>
                        <Typography variant="body1">{$t({ id: 'none' })}</Typography>
                      </MenuItem>
                      {companySelected &&
                        !!licenses &&
                        !loadingLicenses &&
                        Object.entries(licenses).map(([lid, license]) => {
                          const { description } = license;
                          return <MenuItem value={lid}>{`${description} (${lid})`}</MenuItem>;
                        })}
                    </Select>
                  </FormControl>
                ) : (
                  <Skeleton variant="rounded" animation="wave" height={spacing(7)} />
                )}
              </Grid>

              <Grid item xs={12} md={10}>
                {!loadingCompanies && !!licenses ? (
                  <FormControl fullWidth>
                    <InputLabel id="license-select-label">
                      {$t({ id: 'admin.replacementLicense' })}
                    </InputLabel>
                    <Select
                      labelId="rplicense-select-label"
                      id="rplicense-select"
                      disabled={!companySelected || !state.currentLicense}
                      required
                      name="newLicense"
                      onChange={handleSelectChange}
                      value={state.newLicense}
                      input={<OutlinedInput label={$t({ id: 'admin.replacementLicense' })} />}
                    >
                      <MenuItem value={''} disabled>
                        <Typography variant="body1">{$t({ id: 'none' })}</Typography>
                      </MenuItem>
                      {companySelected &&
                        Object.keys(licenses)
                          .filter((l) => !l.match(state.currentLicense))
                          .map((lid) => (
                            <MenuItem value={lid}>
                              {`${licenses[lid].description} (${lid})`}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                ) : (
                  <Skeleton variant="rounded" animation="wave" height={spacing(7)} />
                )}
              </Grid>

              {!loadingCompanies ? (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.deleteCurrent}
                        name="deleteCurrent"
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={
                      <Typography variant="body2">{$t({ id: 'admin.deleteCurrent' })}</Typography>
                    }
                  />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Skeleton
                    variant="text"
                    width={spacing(22)}
                    height={spacing(5)}
                    animation="wave"
                  />
                </Grid>
              )}

              {!loadingCompanies ? (
                <Grid item xs={12}>
                  <LoadingButton
                    content={$t({ id: 'admin.replaceFlyidLicense' })}
                    type="submit"
                    isLoading={ui.loadingButton.isLicenseActionLoading}
                  />
                </Grid>
              ) : (
                <Grid item xs={3}>
                  <Skeleton variant="rounded" height={spacing(5)} animation="wave" />
                </Grid>
              )}
            </Grid>
          </form>
        </Container>
      ) : (
        <>
          <Typography variant="body1">An error occurred: {errorCompanies.message}</Typography>
          <Typography variant="body1">An error occurred: {errorLicenses.message}</Typography>
        </>
      )}
    </>
  );
};

export default ReplaceFlyidLicense;
