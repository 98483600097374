import { Container, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import useStateReducer from 'src/hooks/useStateReducer';
import { changePassword } from 'src/redux/actions/userActions';
import { updateUi } from 'src/redux/reducers/uiReducer';
import { selectProfile } from 'src/redux/selectors/userSelectors';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { isPilotProf } from 'src/util/helpers/user';
import LoadingButton from '../widgets/LoadingButton';

const useStyles = appMakeStyles(({ resizableContainer, spacing }) => ({
  container: {
    ...resizableContainer(2),
    marginLeft: 0,
    maxWidth: '850px'
  },
  mainGrid: {
    minWidth: '650px'
  },
  titleContainer: {
    marginBottom: spacing(4),
    maxWidth: '850px'
  }
}));

const initialChangePasswordData = {
  currentPassword: '',
  newPassword: '',
  confirmation: ''
};
type State = typeof initialChangePasswordData;

const ChangePassword: React.FC = () => {
  const classes = useStyles();
  const { text } = useAppTheme();
  const { $t } = useIntl();
  const dispatch = useAppDispatch();

  const { profile, ui } = useAppSelector((s) => ({ profile: selectProfile(s), ui: s.ui }));

  const [state, setState] = useStateReducer<State>(Object.assign({}, initialChangePasswordData));

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState({ [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const { currentPassword, newPassword, confirmation } = state;

    if (isPilotProf(profile)) {
      if (!/^\d{6}$/.test(newPassword)) {
        dispatch(
          updateUi({
            snackbar: {
              message: $t({ id: 'err.pilotPassword' }),
              severity: 'error',
              show: true
            }
          })
        );
        return;
      }
    }

    if (currentPassword && newPassword && confirmation && newPassword === confirmation) {
      dispatch(changePassword({ currentPassword, newPassword }));
      return;
    }

    updateUi({
      snackbar: {
        message: $t({ id: 'chgPw.noMatch' }),
        severity: 'error',
        show: true
      }
    });
  };
  return (
    <Container className={classes.container}>
      <div className={classes.titleContainer}>
        <Typography variant="h4" sx={text.title}>
          {$t({ id: 'chgPw.title' })}
        </Typography>
        <Typography variant="subtitle1" sx={text.subtitle}>
          {$t({ id: 'chgPw.subtitle' })}
        </Typography>
      </div>

      <form onSubmit={handleSubmit}>
        <Grid container className={classes.mainGrid}>
          <Grid container item spacing={2} xs={5} flexDirection={'column'}>
            <Grid item>
              <TextField
                required
                fullWidth
                variant="outlined"
                id="currentPassword"
                name="currentPassword"
                type="password"
                label={$t({ id: 'chgPw.currentPassword' })}
                value={state.currentPassword}
                onChange={handleTextChange}
              />
            </Grid>
            <Grid item>
              <TextField
                required
                fullWidth
                variant="outlined"
                id="newPassword"
                name="newPassword"
                type="password"
                label={$t({ id: 'chgPw.newPassword' })}
                value={state.newPassword}
                onChange={handleTextChange}
              />
            </Grid>
            <Grid item>
              <TextField
                required
                fullWidth
                variant="outlined"
                id="confirmation"
                name="confirmation"
                type="password"
                label={$t({ id: 'chgPw.confirmation' })}
                value={state.confirmation}
                onChange={handleTextChange}
              />
            </Grid>

            <Grid item>
              <LoadingButton
                content={$t({ id: 'saveChanges' })}
                type="submit"
                isLoading={ui.loadingButton.isUserActionLoading}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default ChangePassword;
