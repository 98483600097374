import React, { Dispatch, SetStateAction, useState } from 'react';

export const encodeText = (str: string) => {
  if (!('TextEncoder' in window)) alert('Sorry, this browser does not support TextEncoder...');
  return new TextEncoder().encode(str);
};

export const decodeText = (buffer: BufferSource) => {
  if (!('TextDecoder' in window)) alert('Sorry, this browser does not support TextDecoder...');
  return new TextDecoder().decode(buffer);
};

export const storeEventCheck =
  (key: string, setter?: Dispatch<SetStateAction<boolean>>) =>
  (e: React.ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(key, JSON.stringify(e.target.checked));
    setter?.(e.target.checked);
  };

export const storeEventValue =
  <R, T extends { value: R }>(key: string, setter?: (v: R) => void) =>
  (e: React.ChangeEvent<Element & T>) => {
    localStorage.setItem(key, JSON.stringify(e.target?.value));
    setter?.(e.target?.value);
  };

export const storeValue = <T>(key: string, value: T, setter?: (v: T) => void) => {
  localStorage.setItem(key, JSON.stringify(value));
  setter?.(value);
};

export function getStoredOrDefault<T>(key: string, def: T): T {
  const value = localStorage.getItem(key);
  // eslint-disable-next-line eqeqeq
  if (value == null) return def;
  try {
    return JSON.parse(value) as T;
  } catch (e) {
    return def;
  }
}

export function useStoredState<T>(key: string, def: T): [T, Dispatch<SetStateAction<T>>] {
  const [state, setState] = useState<T>(getStoredOrDefault(key, def));

  return [
    state,
    (v: SetStateAction<T>) => storeValue(key, v instanceof Function ? v(state) : v, setState)
  ];
}
