import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Container,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
  Typography
} from '@mui/material';
import axios from 'axios';
import { FirebaseError } from 'firebase/app';
import { getDownloadURL, ref } from 'firebase/storage';
import { MaybeKeyUserPublic, PermissionsProps } from 'flyid-core/dist/Database/Models';
import {
  getCompaniesCol,
  getMaybeKeyUserProfileDoc,
  getStorageKeyUserProfileImagePath,
  getStorageUserProfileImagePath,
  getUserProfileDoc
} from 'flyid-core/dist/Util/database';
import MultiSelectButton from 'flyid-ui-components/dist/utils/MultiSelectButton';
import { isEmpty } from 'lodash';
import React, { FormEvent, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionOnce, useDocumentData } from 'react-firebase-hooks/firestore';
import { useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import { auth, profilePicsBucket } from 'src/firebase/firebase';
import { buildCollectionRef, buildDocumentRef, querySnapToMap } from 'src/firebase/firestore';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import useStateReducer from 'src/hooks/useStateReducer';
import { Actions } from 'src/redux/actions/actionTypes';
import { EditUserParams } from 'src/redux/actions/userActions';
import { MyDialogState, updateUi } from 'src/redux/reducers/uiReducer';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import {
  isAssistantProf,
  isKeyUserProf,
  isModeratorProf,
  isPilotProf
} from 'src/util/helpers/user';
import { decodeText } from 'src/util/web';
import { urlDataReader } from 'src/workers/fileWorkerApi';
import LoadingButton from '../widgets/LoadingButton';

const useStyles = appMakeStyles(({ resizableContainer, spacing }) => ({
  container: {
    ...resizableContainer(2),
    marginLeft: 0,
    maxWidth: '850px'
  },
  mainGrid: {
    minWidth: '650px'
  },
  titleContainer: {
    marginBottom: spacing(4),
    maxWidth: '850px'
  },
  margin: {
    marginBottom: spacing(1)
  },
  profileImage: {
    width: spacing(20),
    height: spacing(20)
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: spacing(0.5)
  }
}));

const initialState = {
  firstName: '',
  lastName: '',
  employeeId: '',
  email: '',
  permissions: [] as PermissionsProps,
  authDomains: [] as string[],
  authLicenses: undefined as string[] | undefined,
  profilePicFile: undefined as string | undefined,
  companies: [] as string[]
};
type State = typeof initialState;

const PermissionsKeys: PermissionsProps = ['pilot', 'assistant', 'moderator', 'keyUser'];
const readPermissionsIntoArray = (profile: MaybeKeyUserPublic) => {
  const permissions: PermissionsProps = [];
  if (!isEmpty(profile)) {
    PermissionsKeys.forEach((perm) => {
      const permIsGiven = profile[perm];
      if (permIsGiven) {
        permissions.push(perm);
      }
    });
  }
  return permissions;
};

const UserProfile: React.FC = () => {
  const classes = useStyles();
  const { text, spacing, select } = useAppTheme();
  const { $t } = useIntl();
  const dispatch = useAppDispatch();

  const [state, setState] = useStateReducer<State>(initialState);
  const [profileImage, setProfileImage] = useState<string | undefined>('');

  const { uid: paramUid } = useParams<UserMatchParams>();
  const [user] = useAuthState(auth);
  const myUid = user!.uid;

  const isOwnProfile = myUid === paramUid;

  const [userProfile, loadingUserProfile, errorUserProfile] = useDocumentData(
    buildDocumentRef(getMaybeKeyUserProfileDoc(paramUid))
  );

  const userIsMod = isModeratorProf(userProfile);
  const userIsPilotOrAssistant = isPilotProf(userProfile) || isAssistantProf(userProfile);
  const userIsKeyUser = isKeyUserProf(userProfile);

  const { ui, isPin, usesAuthProvider, userData } = useAppSelector((s) => {
    let isPin = false;
    let usesAuthProvider = false;
    // Users that have pin are non-key-users that are pilots or checkers and
    // are authenticated through some SSO provider
    if (!!userProfile) {
      const provider = s.firestore.authProviders?.providers?.[state.companies[0]]?.provider;
      usesAuthProvider = !!provider;
      isPin = Boolean(!userIsKeyUser && provider && (userProfile?.pilot || userProfile?.checker));
    }
    return { ui: s.ui, isPin, usesAuthProvider, userData: s.user };
  });

  const hasParent = userProfile?.parent;

  const [parentUser, loadingParent] = useDocumentData(
    hasParent ? buildDocumentRef(getUserProfileDoc(hasParent)) : undefined
  );

  const [companiesQS, loadingCompanies] = useCollectionOnce(buildCollectionRef(getCompaniesCol()));
  const companiesMap = querySnapToMap(companiesQS) ?? {};
  const availbleCompanies = Object.keys(companiesMap);

  const fetchProfileImage = () => {
    const storage = profilePicsBucket;
    let path: string | undefined;

    if (paramUid) {
      if (userIsKeyUser) {
        path = getStorageKeyUserProfileImagePath(paramUid);
      } else if ((userIsMod || userIsPilotOrAssistant) && userProfile) {
        path = getStorageUserProfileImagePath(userProfile.company as string, paramUid);
      }
    }

    if (typeof path === 'string') {
      getDownloadURL(ref(storage, path))
        .then((url) => {
          axios({
            baseURL: undefined,
            url,
            method: 'GET',
            responseType: 'blob'
          })
            .then((res) =>
              urlDataReader(res.data as Blob, {
                onload: (result) => {
                  const src = result
                    ? typeof result === 'string'
                      ? result
                      : decodeText(result)
                    : undefined;
                  setProfileImage(src);
                },
                onerror: (err) => console.log(err)
              })
            )
            .catch((err) => {
              if (err instanceof FirebaseError) {
                if (String(err.code) !== '404' && err.code !== 'storage/object-not-found')
                  console.error(err);
              }
              setProfileImage(undefined);
            });
        })
        .catch((error) => {
          console.error('Error when searching for profile image:', error);
          setProfileImage(undefined);
        });
    }
  };

  const showPasswordResetConfirmation = () => {
    const { firstName, lastName } = state;

    dispatch(
      updateUi({
        dialog: new MyDialogState({
          title: $t({ id: `prof.${isPin ? 'pin' : 'pw'}ResetTitle` }),
          message: $t(
            { id: `prof.${isPin ? 'pin' : 'pw'}ResetMsg` },
            {
              name: (
                <b key="uob0">
                  {firstName} {lastName}
                </b>
              ),
              nl: <br key="upnl0" />
            }
          ),
          useCheckbox: false,
          show: true
        }).setConfirmAction(Actions.RESET_USER_PASSWORD, { uid: paramUid, isPin })
      })
    );
  };

  useEffect(() => {
    fetchProfileImage();
  }, [userProfile, paramUid]);

  useEffect(() => {
    if (userProfile) {
      setState({
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        email: userProfile.email,
        employeeId: userProfile.employeeId,
        permissions: readPermissionsIntoArray(userProfile),
        authDomains: userProfile.authDomains,
        authLicenses: userProfile.authLicenses,
        companies: userIsKeyUser
          ? (userProfile.company as string[])
          : [userProfile.company as string]
      });
    }
  }, [userProfile]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (userProfile) {
      const company = userProfile.company;
      const userData: EditUserParams = {
        uid: paramUid,
        company: userIsKeyUser ? (company as string[])[0] : (company as string),
        email: state.email,
        firstName: state.firstName,
        lastName: state.lastName,
        employeeId: state.employeeId,
        authDomains: state.authDomains,
        authLicenses: state.authLicenses,
        moderator: !!state.permissions.includes('moderator'),
        assistant: !!state.permissions.includes('assistant'),
        pilot: !!state.permissions.includes('pilot'),
        keyUser: userIsKeyUser
      };

      if (userIsKeyUser) {
        userData.companies = state.companies;
      }

      dispatch(
        updateUi({
          dialog: new MyDialogState({
            title: $t({ id: 'admin.editUsrConfTitle' }),
            message: $t(
              { id: 'admin.editUsrConfMsg' },
              {
                name: (
                  <b key="uob0">
                    {userData.firstName} {userData.lastName}
                  </b>
                )
              }
            ),
            show: true
          }).setConfirmAction(Actions.EDIT_USER, userData)
        })
      );
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();

    const file = e.target.files?.[0];
    if (file) {
      urlDataReader(file, {
        onload: (result) => {
          const src = result ? (typeof result === 'string' ? result : decodeText(result)) : '';
          setProfileImage(src);
        }
      });
    }
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState({ [e.target.name]: e.target.value });
  };

  const handleMultipleSelectChange = (e: SelectChangeEvent<string[]>) => {
    setState({ [e.target.name]: e.target.value });
  };

  return (
    <>
      {!errorUserProfile ? (
        <Container className={classes.container}>
          {!loadingUserProfile && !!userProfile ? (
            <div className={classes.titleContainer}>
              <Typography variant="h4" sx={text.title}>
                {$t(
                  { id: 'admin.editUsrTitle' },
                  { name: `${userProfile.firstName} ${userProfile.lastName}` }
                )}
              </Typography>
              <Typography variant="subtitle1" sx={text.subtitle} className={classes.margin}>
                {$t({ id: 'admin.editUsrSubtitle' })}
              </Typography>
            </div>
          ) : (
            <div className={classes.titleContainer}>
              <Skeleton variant="text" height={spacing(8)} animation="wave" />
              <Skeleton variant="text" height={spacing(4)} animation="wave" />
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} className={classes.mainGrid}>
              {/* Profile picture container */}
              <Grid container item spacing={2} direction="column" xs={4} alignItems="center">
                <Grid item>
                  {!loadingUserProfile ? (
                    <Avatar
                      src={profileImage}
                      className={classes.profileImage}
                      alt={$t({ id: 'altUserProfileImage' })}
                    />
                  ) : (
                    <Skeleton
                      variant="circular"
                      className={classes.profileImage}
                      animation="wave"
                    />
                  )}
                </Grid>
                <Grid item>
                  {!loadingUserProfile ? (
                    <>
                      <Box
                        component="input"
                        accept="image/jpeg, image/png"
                        sx={{ display: 'none' }}
                        id="profile-image-btn"
                        type="file"
                        onChange={handleFileChange}
                      />
                      <label htmlFor="profile-image-btn">
                        <Button
                          variant="outlined"
                          component="span"
                          disabled={ui.loadingButton.isUserActionLoading}
                        >
                          {$t({ id: 'prof.chgPicture' })}
                        </Button>
                      </label>
                    </>
                  ) : (
                    <Skeleton
                      variant="rounded"
                      height={spacing(5)}
                      width={spacing(20)}
                      animation="wave"
                    />
                  )}
                </Grid>
              </Grid>

              <Grid container item spacing={1} xs={8} alignContent="flex-start">
                {/* First and Last names */}
                {!loadingUserProfile ? (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        id="firstName"
                        name="firstName"
                        type="text"
                        label={$t({ id: 'firstName' })}
                        value={state.firstName}
                        onChange={handleTextChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        id="lastName"
                        name="lastName"
                        type="text"
                        label={$t({ id: 'lastName' })}
                        value={state.lastName}
                        onChange={handleTextChange}
                      />
                    </Grid>
                  </>
                ) : (
                  <Box sx={{ mt: spacing(2), display: 'flex' }}>
                    <Skeleton
                      variant="rounded"
                      height={spacing(7)}
                      width={spacing(25)}
                      animation="wave"
                      sx={{ mr: spacing(2), ml: spacing(1) }}
                    />
                    <Skeleton
                      variant="rounded"
                      height={spacing(7)}
                      width={spacing(25)}
                      animation="wave"
                    />
                  </Box>
                )}

                {/* EmployeeId and email */}
                {!loadingUserProfile ? (
                  <>
                    <Grid item xs={4}>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        id="employeeId"
                        name="employeeId"
                        type="text"
                        label={$t({ id: 'employeeId' })}
                        value={state.employeeId}
                        onChange={handleTextChange}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        id="email"
                        name="email"
                        type="email"
                        label={$t({ id: 'email' })}
                        value={state.email}
                        onChange={handleTextChange}
                      />
                    </Grid>
                  </>
                ) : (
                  <Box sx={{ mt: spacing(1), display: 'flex' }}>
                    <Skeleton
                      variant="rounded"
                      height={spacing(7)}
                      width={spacing(15)}
                      animation="wave"
                      sx={{ mr: spacing(1), ml: spacing(1) }}
                    />
                    <Skeleton
                      variant="rounded"
                      height={spacing(7)}
                      width={spacing(36)}
                      animation="wave"
                    />
                  </Box>
                )}

                {/* Permissions */}
                {!loadingUserProfile ? (
                  <Grid item xs={12}>
                    <FormControl fullWidth sx={{ mt: 0.5 }} error={isEmpty(state.permissions)}>
                      <InputLabel id="permissions-label">{$t({ id: 'permissions' })}</InputLabel>
                      <Select
                        labelId="permissions-label"
                        id="permissions"
                        name="permissions"
                        disabled={userIsKeyUser}
                        multiple
                        className={classes.margin}
                        value={state.permissions}
                        onChange={handleMultipleSelectChange}
                        renderValue={(selected) => (
                          <div className={classes.chips}>
                            {selected.map((value) => (
                              <Chip key={value} label={$t({ id: value })} />
                            ))}
                          </div>
                        )}
                        MenuProps={select.getMenuProps()}
                        input={<OutlinedInput label={$t({ id: 'permissions' })} />}
                      >
                        {PermissionsKeys.map((perm) => (
                          <MenuItem
                            key={perm}
                            value={perm}
                            disabled={perm === 'moderator' || perm === 'keyUser'}
                          >
                            <Checkbox checked={state.permissions.indexOf(perm) > -1} />
                            <ListItemText primary={$t({ id: perm })} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : (
                  <Skeleton
                    variant="rounded"
                    height={spacing(8)}
                    width={spacing(52)}
                    animation="wave"
                    sx={{ mt: spacing(1), ml: spacing(1) }}
                  />
                )}
              </Grid>

              {/* AuthDomains */}
              {!loadingUserProfile &&
              !loadingParent &&
              !userIsKeyUser &&
              (userIsPilotOrAssistant || userIsMod) ? (
                <Grid item xs={12}>
                  <FormControl required fullWidth error={isEmpty(state.authDomains)}>
                    <InputLabel id="authdomains-label">{$t({ id: 'authDomains' })}</InputLabel>
                    <Select
                      fullWidth
                      labelId="authdomains-label"
                      id="authdomains"
                      name="authDomains"
                      multiple
                      disabled={userIsMod}
                      value={state.authDomains}
                      onChange={handleMultipleSelectChange}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: spacing(0.5) }}>
                          {selected.map((domain) => (
                            <Chip key={`rend${domain}`} label={domain} className={classes.chip} />
                          ))}
                        </Box>
                      )}
                      input={<OutlinedInput label={$t({ id: 'authDomains' })} />}
                      MenuProps={select.getMenuProps()}
                    >
                      {userIsPilotOrAssistant &&
                        parentUser?.authDomains.map((domain) => (
                          <MenuItem key={`men${domain}`} value={domain}>
                            <Checkbox checked={state.authDomains.indexOf(domain) > -1} />
                            <ListItemText primary={domain} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              ) : !userIsKeyUser ? (
                <Skeleton
                  variant="rounded"
                  height={spacing(8)}
                  width={spacing(80)}
                  animation="wave"
                  sx={{ mt: spacing(2), ml: spacing(2) }}
                />
              ) : null}

              {/* Authlicenses */}
              {!loadingUserProfile && userIsMod ? (
                <Grid item xs={12}>
                  <FormControl required fullWidth error={isEmpty(state.authLicenses)}>
                    <InputLabel id="authLicenses-label">{$t({ id: 'authLicenses' })}</InputLabel>
                    <Select
                      fullWidth
                      labelId="authLicenses-label"
                      id="authLicenses"
                      name="authLicenses"
                      multiple
                      className={classes.margin}
                      disabled={userIsMod}
                      value={Array.isArray(state.authLicenses) ? state.authLicenses : []}
                      onChange={handleMultipleSelectChange}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: spacing(0.5) }}>
                          {selected.map((license) => (
                            <Chip key={`rend${license}`} label={license} className={classes.chip} />
                          ))}
                        </Box>
                      )}
                      input={<OutlinedInput label={$t({ id: 'authLicenses' })} />}
                      MenuProps={select.getMenuProps()}
                    ></Select>
                  </FormControl>
                </Grid>
              ) : !userIsKeyUser && !userIsPilotOrAssistant ? (
                <Skeleton
                  variant="rounded"
                  height={spacing(8)}
                  width={spacing(80)}
                  animation="wave"
                  sx={{ mt: spacing(2), ml: spacing(2) }}
                />
              ) : null}

              {/* Companies (keyUser access) */}
              {!loadingUserProfile && !loadingCompanies && userIsKeyUser ? (
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    error={isEmpty(state.companies)}
                    className={classes.margin}
                  >
                    <InputLabel id="companies-select-label">{$t({ id: 'companies' })}</InputLabel>
                    <MultiSelectButton
                      options={availbleCompanies}
                      onChange={(e) =>
                        handleMultipleSelectChange({
                          target: { name: 'companies', value: e }
                        } as SelectChangeEvent<string[]>)
                      }
                      initialSelection={state.companies}
                      selectProps={{
                        labelId: 'companies-select-label',
                        input: <OutlinedInput label={$t({ id: 'companies' })} />
                      }}
                    />
                  </FormControl>
                </Grid>
              ) : userIsKeyUser ? (
                <Skeleton
                  variant="rounded"
                  height={spacing(8)}
                  width={spacing(80)}
                  animation="wave"
                  sx={{ mt: spacing(2), ml: spacing(2) }}
                />
              ) : null}

              <Grid container item xs={12}>
                {!loadingUserProfile ? (
                  <Grid item xs={8}>
                    <LoadingButton
                      content={$t({ id: 'saveChanges' })}
                      type="submit"
                      isLoading={ui.loadingButton.isUserActionLoading}
                    />
                    <Button
                      component={Link}
                      to={'/manageusers'}
                      sx={{ ml: spacing(2), height: spacing(5) }}
                      color="error"
                      variant="contained"
                    >
                      {$t({ id: 'cancel' })}
                    </Button>
                  </Grid>
                ) : (
                  <Grid container item xs={8} alignContent="flex-start">
                    <Grid item xs={4}>
                      <Skeleton
                        variant="rounded"
                        height={spacing(5)}
                        width={spacing(18)}
                        animation="wave"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Skeleton
                        variant="rounded"
                        height={spacing(5)}
                        width={spacing(10)}
                        animation="wave"
                        sx={{ ml: spacing(2) }}
                      />
                    </Grid>
                  </Grid>
                )}

                {!loadingUserProfile ? (
                  !usesAuthProvider ? (
                    <Grid item xs={4} textAlign="right">
                      <Button
                        component={Link}
                        to={isOwnProfile ? (isPin ? '/changepin' : '/changepw') : '#'}
                        onClick={isOwnProfile ? () => ({}) : showPasswordResetConfirmation}
                        sx={{ height: spacing(5) }}
                        color="primary"
                        variant="contained"
                      >
                        {$t({ id: `prof.${isOwnProfile ? 'chg' : 'rst'}${isPin ? 'Pin' : 'Pw'}` })}
                      </Button>
                    </Grid>
                  ) : null
                ) : (
                  <Grid item xs={4} textAlign="right">
                    <Skeleton
                      variant="rounded"
                      height={spacing(5)}
                      width={spacing(23)}
                      animation="wave"
                      sx={{ ml: spacing(3) }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </form>
        </Container>
      ) : (
        <Typography variant="body1">An error occurred: {errorUserProfile.message}</Typography>
      )}
    </>
  );
};

export default UserProfile;
