import {
  Box,
  Checkbox,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material';
import { FeatureLocks, FeatureLocksKeys } from 'flyid-core/dist/Database/Models';
import MultiSelectButton from 'flyid-ui-components/dist/utils/MultiSelectButton';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import useStateReducer from 'src/hooks/useStateReducer';
import { Actions } from 'src/redux/actions/actionTypes';
import { SetupCompanyParams } from 'src/redux/actions/companyActions';
import { MyDialogState, updateUi } from 'src/redux/reducers/uiReducer';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import LoadingButton from '../widgets/LoadingButton';

const useStyles = appMakeStyles((theme) => ({
  container: {
    ...theme.resizableContainer(2),
    marginLeft: 0,
    maxWidth: '800px'
  },
  mainGrid: {
    minWidth: '650px'
  },
  titleContainer: {
    marginBottom: theme.spacing(4),
    maxWidth: '850px'
  },
  margin: {
    marginBottom: theme.spacing(1)
  },
  button: {
    margin: 0
  }
}));

const initialCompanyData = {
  company: '',
  exhibitionName: '',
  timezone: '',
  featureLocks: [] as string[],
  moderator: undefined,
  email: '',
  firstName: '',
  lastName: '',
  employeeId: '',
  sendEmail: false
};
type State = typeof initialCompanyData;

const AddCompany: React.FC = () => {
  const classes = useStyles();
  const { text } = useAppTheme();
  const { $t } = useIntl();
  const dispatch = useAppDispatch();

  const [state, setState] = useStateReducer<State>(Object.assign({}, initialCompanyData));
  const [addModerador, setAddModerator] = useState(false);
  const [selectedFeatureLocks, setSelectedFeatureLocks] = useState<string[]>([]);

  const { ui } = useAppSelector((s) => ({
    ui: s.ui
  }));

  const gmtTimezones = moment.tz.names().filter((tz) => tz.startsWith('Etc/GMT'));
  const timezones = gmtTimezones.map((tz) => tz.replace(/^Etc\//, ''));

  type FeatureLocksKey = keyof FeatureLocks;
  const featureLocksLabels: { [key in FeatureLocksKey]: string } = {
    pictureTaking: $t({ id: 'featureLocks.takingPictures' })
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!!addModerador) {
      const companyData: SetupCompanyParams = {
        company: state.company,
        exhibitionName: state.exhibitionName,
        timezone: state.timezone,
        moderator: {
          email: state.email,
          firstName: state.firstName,
          lastName: state.lastName,
          employeeId: state.employeeId
        },
        sendEmail: state.sendEmail,
        featureLocks: selectedFeatureLocks
      };

      dispatch(
        updateUi({
          dialog: new MyDialogState({
            title: $t({ id: 'admin.addCompanyConfTitle' }),
            message: $t({ id: 'admin.addCompanyConfMsg' }),
            show: true
          }).setConfirmAction(Actions.SETUP_COMPANY, companyData)
        })
      );
    } else {
      const companyData: SetupCompanyParams = {
        company: state.company,
        exhibitionName: state.exhibitionName,
        timezone: state.timezone,
        featureLocks: selectedFeatureLocks,
        moderator: undefined,
        sendEmail: false
      };

      dispatch(
        updateUi({
          dialog: new MyDialogState({
            title: $t({ id: 'admin.addCompanyConfTitle' }),
            message: $t({ id: 'admin.addCompanyConfMsg' }),
            show: true
          }).setConfirmAction(Actions.SETUP_COMPANY, companyData)
        })
      );
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ [e.target.name]: e.target.checked });
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    // eslint-disable-next-line prefer-const
    let { value, name } = e.target;
    // Only allow alphanumeric and underline on company value.
    if (name === 'company') {
      value = value.replace(/[^a-z0-9_]/g, '');
    }
    setState({ [name]: value });
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    setState({ [e.target.name]: e.target.value });
  };

  const handleMultipleFeatureLocksSelectChange = (featureLock: string[]) => {
    setSelectedFeatureLocks(featureLock);
  };

  return (
    <Container className={classes.container}>
      <div className={classes.titleContainer}>
        <Typography variant="h4" sx={text.title}>
          {$t({ id: 'admin.addCompany' })}
        </Typography>
        <Typography variant="subtitle1" sx={text.subtitle}>
          {$t({ id: 'admin.addCompanySubtitle' }, { nl: <br key="munl0" /> })}
        </Typography>
      </div>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} className={classes.mainGrid}>
          <Grid container item spacing={1} alignContent="flex-start">
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                id="company"
                name="company"
                type="text"
                value={state.company}
                onChange={handleTextChange}
                label={$t({ id: 'admin.companyName' })}
                autoFocus
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                id="exhibitionName"
                name="exhibitionName"
                type="text"
                value={state.exhibitionName}
                onChange={handleTextChange}
                label={$t({ id: 'admin.exhibitionName' })}
              />
            </Grid>

            <Grid item xs={8}>
              <FormControl fullWidth>
                <InputLabel id="feature-locks-select-label">
                  {$t({ id: 'admin.selectFeatureLock' })}
                </InputLabel>
                <MultiSelectButton
                  options={FeatureLocksKeys}
                  onChange={handleMultipleFeatureLocksSelectChange}
                  selectProps={{
                    labelId: 'feature-locks-select-label',
                    input: <OutlinedInput label={$t({ id: 'admin.selectFeatureLock' })} />,
                    renderValue: (selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, maxHeight: 5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={featureLocksLabels[value as FeatureLocksKey]} />
                        ))}
                      </Box>
                    )
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="timezone-select-label">
                  {$t({ id: 'admin.selectTimezone' })}
                </InputLabel>
                <Select
                  labelId="timezone-select-label"
                  id="timezone-select"
                  name="timezone"
                  value={state.timezone}
                  onChange={handleSelectChange}
                  input={<OutlinedInput label={$t({ id: 'admin.selectTimezone' })} />}
                >
                  <MenuItem value="">
                    <Typography variant="body1">{$t({ id: 'none' })}</Typography>
                  </MenuItem>
                  {timezones.map((timezone, index) => (
                    <MenuItem key={index} value={timezone}>
                      {timezone}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={addModerador}
                  onChange={(e) => setAddModerator(e.target.checked)}
                />
              }
              label={
                <Typography variant="body2">{$t({ id: 'admin.associateModerator' })}</Typography>
              }
            />
          </Grid>

          <Grid container item xs={12} columnSpacing={1} alignContent="flex-start">
            {addModerador ? (
              <>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    required
                    id="email"
                    name="email"
                    type="email"
                    label={$t({ id: 'addUsr.email' })}
                    value={state.email}
                    onChange={handleTextChange}
                    className={classes.margin}
                    autoFocus
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    id="employeeId"
                    name="employeeId"
                    type="text"
                    label={$t({ id: 'employeeId' })}
                    value={state.employeeId}
                    onChange={handleTextChange}
                    className={classes.input}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    required
                    id="firstName"
                    name="firstName"
                    type="text"
                    label={$t({ id: 'firstName' })}
                    value={state.firstName}
                    onChange={handleTextChange}
                    className={classes.margin}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    required
                    id="lastName"
                    name="lastName"
                    type="text"
                    label={$t({ id: 'lastName' })}
                    value={state.lastName}
                    onChange={handleTextChange}
                    className={classes.margin}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.sendEmail}
                        name="sendEmail"
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={
                      <Typography variant="body2">
                        {$t({ id: 'admin.sendEmailForConfirmation' })}
                      </Typography>
                    }
                  />
                </Grid>
              </>
            ) : null}
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              className={classes.button}
              content={$t({ id: 'admin.addCompany' })}
              type="submit"
              isLoading={ui.loadingButton.isCompanyActionLoading}
            />
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default AddCompany;
