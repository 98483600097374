import axios, { AxiosRequestConfig } from 'axios';
import { Auth } from 'firebase/auth';
import { cloneDeep } from 'lodash';
import { selectProfile } from 'src/redux/selectors/userSelectors';
import { AppState } from 'src/redux/store';

const authAxios = (auth: Auth, state: AppState, config: AxiosRequestConfig) => {
  const headers = cloneDeep(config.headers) ?? {};
  if (headers) delete config.headers;

  const currUser = auth.currentUser;
  if (!currUser) throw Error('Not logged in!');

  // If KeyUser as well, fill in company for actions that not necessarily require it, for auth consistency sake.
  const currUserProfile = selectProfile(state);
  if (currUserProfile?.keyUser && !headers?.company) {
    headers.company = (currUserProfile.company as unknown as string[])[0];
  }

  return currUser.getIdToken(true).then((token) =>
    axios({
      headers: {
        Authorization: `Bearer ${token}`,
        'Accept-Language': state.locale.locale,
        ...headers
      },
      withCredentials: true,
      ...config
    })
  );
};

export default authAxios;
