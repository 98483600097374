import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Skeleton,
  Typography
} from '@mui/material';
import { query, where } from 'firebase/firestore';
import { getUserProfileDoc, getUsersCol } from 'flyid-core/dist/Util/database';
import React, { useEffect } from 'react';
import { useCollectionOnce, useDocumentData } from 'react-firebase-hooks/firestore';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { buildCollectionRef, buildDocumentRef, querySnapToMap } from 'src/firebase/firestore';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import useStateReducer from 'src/hooks/useStateReducer';
import { Actions } from 'src/redux/actions/actionTypes';
import { ReplaceModeratorParams } from 'src/redux/actions/userActions';
import { MyDialogState, updateUi } from 'src/redux/reducers/uiReducer';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import LoadingButton from '../widgets/LoadingButton';

const useStyles = appMakeStyles(({ spacing, resizableContainer }) => ({
  container: {
    ...resizableContainer(2),
    marginLeft: 0,
    maxWidth: '900px'
  },
  mainGrid: {
    minWidth: '650px'
  },
  titleContainer: {
    marginBottom: spacing(4),
    maxWidth: '900px'
  }
}));
const initialReplaceModeratorData = {
  fromUid: '',
  toUid: ''
};
type State = typeof initialReplaceModeratorData;

const ReplaceModerator: React.FC = () => {
  const classes = useStyles();
  const { text, spacing } = useAppTheme();
  const { $t } = useIntl();
  const dispatch = useAppDispatch();

  const { ui } = useAppSelector((s) => ({
    ui: s.ui
  }));

  const [state, setState] = useStateReducer<State>(Object.assign({}, initialReplaceModeratorData));

  const { uid } = useParams<UserMatchParams>();

  const [currentModerator, loadingModerator, errorModerator] = useDocumentData(
    buildDocumentRef(getUserProfileDoc(uid))
  );

  const [userQS, loadingUsers, errorUsers] = useCollectionOnce(
    currentModerator
      ? query(buildCollectionRef(getUsersCol()), where('parent', '==', uid))
      : undefined
  );

  const users = querySnapToMap(userQS);

  const buildModeratorInfo = () => {
    let userData = 'User data not found';
    if (currentModerator) {
      userData = `${currentModerator.firstName} ${currentModerator.lastName} (${uid})`;
    }
    return userData;
  };

  useEffect(() => {
    if (uid) {
      setState({
        fromUid: uid
      });
    }
  }, [uid]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const moderatorData: ReplaceModeratorParams = {
      fromUid: state.fromUid,
      toUid: state.toUid
    };

    dispatch(
      updateUi({
        dialog: new MyDialogState({
          title: $t({ id: 'admin.replaceModConfTitle' }),
          message: $t({ id: 'admin.replaceModConfMsg' }),
          show: true
        }).setConfirmAction(Actions.REPLACE_MODERATOR, moderatorData)
      })
    );
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    setState({ [e.target.name]: e.target.value });
  };

  return (
    <>
      {!errorModerator || !errorUsers ? (
        <Container className={classes.container}>
          {!loadingModerator ? (
            <div className={classes.titleContainer}>
              <Typography variant="h4" sx={text.title}>
                {$t({ id: 'admin.replaceMod' })}
              </Typography>
              <Typography variant="subtitle1" sx={text.subtitle}>
                {$t(
                  { id: 'admin.replaceModSubtitle' },
                  { nl1: <br key="nl1" />, nl2: <br key="nl2" /> }
                )}
              </Typography>
            </div>
          ) : (
            <div className={classes.titleContainer}>
              <Skeleton variant="text" height={spacing(8)} animation="wave" />
              <Skeleton variant="text" height={spacing(4)} animation="wave" />
            </div>
          )}

          <form onSubmit={handleSubmit}>
            <Grid container className={classes.mainGrid} spacing={2}>
              <Grid item xs={12} md={10}>
                {!loadingModerator && !!currentModerator ? (
                  <FormControl fullWidth>
                    <InputLabel id="crmod-select-label">
                      {$t({ id: 'admin.currentModerator' })}
                    </InputLabel>
                    <Select
                      labelId="crmod-select-label"
                      id="fromUid"
                      disabled
                      name="fromUid"
                      value={state.fromUid ?? ''}
                      input={<OutlinedInput label={$t({ id: 'admin.currentModerator' })} />}
                    >
                      <MenuItem value={''}>
                        <Typography variant="body1">{$t({ id: 'none' })}</Typography>
                      </MenuItem>
                      {currentModerator && <MenuItem value={uid}>{buildModeratorInfo()}</MenuItem>}
                    </Select>
                  </FormControl>
                ) : (
                  <Skeleton variant="rounded" animation="wave" height={spacing(7)} />
                )}
              </Grid>

              <Grid item xs={12} md={10}>
                {!loadingModerator ? (
                  <FormControl fullWidth>
                    <InputLabel id="users-select-label">
                      {$t({ id: 'admin.replacementUser' })}
                    </InputLabel>
                    <Select
                      labelId="users-select-label"
                      required
                      id="toUid"
                      name="toUid"
                      onChange={handleSelectChange}
                      value={state.toUid}
                      input={<OutlinedInput label={$t({ id: 'admin.replacementUser' })} />}
                    >
                      <MenuItem value={''} disabled>
                        <Typography variant="body1">{$t({ id: 'none' })}</Typography>
                      </MenuItem>
                      {!loadingUsers && !!users ? (
                        Object.entries(users)
                          .filter(([userId]) => userId !== state.fromUid)
                          .map(([userId, userData], index) => (
                            <MenuItem key={index} value={userId}>
                              {userData.firstName} {userData.lastName} ({userId})
                            </MenuItem>
                          ))
                      ) : (
                        <MenuItem value={''} disabled>
                          <Typography variant="body1">{$t({ id: 'none' })}</Typography>
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                ) : (
                  <Skeleton variant="rounded" animation="wave" height={spacing(7)} />
                )}
              </Grid>

              {!loadingModerator ? (
                <Grid item>
                  <LoadingButton
                    content={$t({ id: 'replace' })}
                    type="submit"
                    sx={{ mt: spacing(1) }}
                    isLoading={ui.loadingButton.isUserActionLoading}
                  />
                </Grid>
              ) : (
                <Grid item>
                  <Skeleton
                    variant="rounded"
                    width={spacing(15)}
                    height={spacing(5)}
                    animation="wave"
                  />
                </Grid>
              )}
            </Grid>
          </form>
        </Container>
      ) : (
        <>
          <Typography variant="body1">An error occurred: {errorModerator.message}</Typography>
          <Typography variant="body1">An error occurred: {errorUsers.message}</Typography>
        </>
      )}
    </>
  );
};

export default ReplaceModerator;
